import Breadcrumb from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import TableContainer from "components/Common/TableContainer"
import { deletePlan, getAllPlans } from "helpers/backendHelpers/plan"
import {
  SimpleNumberValue,
  SimpleStringValue,
} from "helpers/common_helper_functions"
import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

const Plan = props => {
  const [plans, setPlans] = useState([])
  const [loading, setLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [plan, setPlan] = useState({})
  const [save, setSave] = useState(false)

  useEffect(() => {
    document.title = "Plan |  React Admin & Plan Template"

    fetchAllPlans()
  }, [save])

  //* fetch all plans
  const fetchAllPlans = async () => {
    try {
      setLoading(true)
      const response = await getAllPlans()
      setPlans(response?.payload?.data)
      setLoading(false)
    } catch (error) {}
  }

  //* handle add click
  const handleAddButtonClick = () => {
    props.history.push("/plan/add")
  }

  //* open delete modal
  const onClickDelete = Data => {
    setPlan(Data)
    setDeleteModal(true)
  }

  //* handle Delete plan
  const handleDeletePlan = async () => {
    try {
      await deletePlan(plan._id)
      setSave(!save)
      setDeleteModal(false)
    } catch (error) {}
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Plan Amount",
        accessor: "planAmount",
        filterable: true,
        Cell: cellProps => {
          return <SimpleNumberValue {...cellProps} />
        },
      },
      {
        Header: "Listing Amount",
        accessor: "listingAmount",
        filterable: true,
        Cell: cellProps => {
          return <SimpleNumberValue {...cellProps} />
        },
      },

      {
        Header: "Plan Type",
        accessor: "planType",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "No. of allowed gst",
        accessor: "noOfAllowedGST",
        filterable: true,
        Cell: cellProps => {
          return <SimpleNumberValue {...cellProps} />
        },
      },
      {
        Header: "Banner image",
        accessor: "bannerImageUrl",
        filterable: true,
        Cell: cellProps => {
          const data = cellProps?.row.original

          return (
            <a href={data.bannerImageUrl} target="_blank" rel="noreferrer">
              <img src={data.bannerImageUrl} width={50} alt="" />
            </a>
          )
        },
      },

      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-primary"
                onClick={() => {
                  props.history.push("/plan/view/" + cellProps.row.original._id)
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  props.history.push("/plan/edit/" + cellProps.row.original._id)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePlan}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Plan" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={plans}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    addButtonLabel="Add Plans"
                    handleAddButtonClick={handleAddButtonClick}
                    dataFetchLoading={loading}
                    customPageSize={100}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Plan
