import { getApiConfig } from "../authHelper"

import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

export const getInquiry = async status => {
  if (status == undefined) {
    status = ""
  }
  return await get(
    `${url.SUBSCRIPTION_ENTERPRISE}?sortOder=-1${status}`,
    getApiConfig()
  )
}

export const updateSubscriptionEnterprise = async (id, data) => {
  return await put(
    `${url.SUBSCRIPTION_ENTERPRISE}/admin/${id}`,
    data,
    getApiConfig()
  )
}

export const createSubscriptionPlan = async data => {
  return await post(
    `${url.SUBSCRIPTION_ENTERPRISE}/admin`,
    data,
    getApiConfig()
  )
}

export const deleteSubscriptionEnterprise = async id => {
  return await del(`${url.SUBSCRIPTION_ENTERPRISE}/${id}`, getApiConfig())
}

export const updatePaymentStatus = async (id, data) => {
  return await put(
    `${url.SUBSCRIPTION_ENTERPRISE}/isPaymentLinksend/${id}`,
    data,
    getApiConfig()
  )
}
