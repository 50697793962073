import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { logoutUser } from "../../store/actions"

//redux
import { removeAuthToken } from "helpers/authHelper"

const Logout = props => {
  useEffect(() => {
    logout(props)
  }, [])

  // Logout
  const logout = () => {
    try {
      removeAuthToken()
      props.history.push("/login")
    } catch (error) {
      throw new Error(error)
    }
  }

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
