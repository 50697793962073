import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import { withRouter, Link } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

//Import config
import { setAuthToken, setUserInfo } from "helpers/authHelper"
import bill_image from "../../assets/images/login-bill-image.jpg"
import Logo from "../../assets/images/billsaathi.gif"

import { loginUser } from "helpers/backendHelpers/login"

const Login = props => {
  //meta title
  document.title = "Login | Bill Saathi Admin"

  const [error, setError] = useState("")

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("Please Enter Your userName"),
      password: Yup.string().required("Please Enter Your password"),
    }),
    onSubmit: async values => {
      try {
        const responseData = await loginUser(values)
        if (responseData) {
          setAuthToken(responseData?.payload?.token)
          return props.history.push("/dashboard")
        }
      } catch (error) {}
    },
  })

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <section className="vh-100" style={{ backgroundColor: "#A9A9A9" }}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-xl-10">
              <div
                className="card"
                style={{ borderRadius: "1rem", boxShadow: "0 0.75rem 1.5rem" }}
              >
                <div className="row g-0">
                  <div className="col-md-6 col-lg-5 d-none d-md-block">
                    <img
                      src={bill_image}
                      alt="login form"
                      className="img-fluid"
                      style={{ borderRadius: "1rem 0 0 1rem" }}
                    />
                  </div>
                  <div className="col-md-6 col-lg-7 d-flex align-items-center">
                    <div className="card-body p-4 p-lg-5 text-black d-grid h-100">
                      <div className="text-center">
                        <img src={Logo} alt="" width={200} className="m-auto" />
                      </div>
                      <div>
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="form-outline mb-4">
                            <Label>Username</Label>
                            <Input
                              type="text"
                              id="form2Example17"
                              className="form-control"
                              name="userName"
                              placeholder="Enter User Name"
                              onChange={validation.handleChange}
                              value={validation.values.userName}
                              invalid={
                                validation.touched.userName &&
                                validation.errors.userName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.userName &&
                            validation.errors.userName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.userName}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="form-outline mb-4">
                            <Label>Password</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder="Enter Password"
                              id="form2Example27"
                              className="form-control"
                              onChange={validation.handleChange}
                              value={validation.values.password}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="pt-1 mb-4 w-100">
                            <button
                              className="w-100 btn btn-dark btn-block login-btn"
                              type="submit"
                            >
                              Login
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
