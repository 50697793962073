import Breadcrumb from "components/Common/Breadcrumb"
import TableContainer from "components/Common/TableContainer"
import { Formik, validateYupSchema } from "formik"
import {
  createNotification,
  getAllNotification,
  sentNotification
} from "helpers/backendHelpers/notification"
import { getAllUser } from "helpers/backendHelpers/user"
import { ManageBooleanValue, SimpleStringValue } from "helpers/common_helper_functions"
// import PieChart from "pages/Charts/SplineArea";
import React, { useEffect, useMemo, useState } from "react"
import { Container, Modal, ModalHeader, ModalBody } from "reactstrap"

import Select from "react-select"

import {
  Col,
  Row,
  Form,
  Input,
  Label,
  Card,
  CardBody,
  Button,
} from "reactstrap"

const Notification = () => {
  const [notification, setNotification] = useState({
    user: [],
    allNotification: [],
    SelectedOptions: [],
  })
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({})
  const [save, setSave] = useState(false)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    fetchAllNotifications()
  }, [save])

  //* fetch all notifications
  const fetchAllNotifications = async () => {
    try {
      setLoading(true)
      const response = await getAllNotification()
      setNotification(prevData => ({
        ...prevData,
        allNotification: response?.payload?.data,
      }))
      setLoading(false)

      //* get all user
      const user = await getAllUser()
      const finalUserData = user?.payload?.data.map(item => {
        return (item = {
          value: item.userName,
          label: item.userName,
          id: item._id,
        })
      })
      setNotification(prevData => ({
        ...prevData,
        user: finalUserData,
      }))
    } catch (error) {
      setLoading(false)
    }
  }

  //* handle select on change in user
  const handleSelectChange = selectedOptions => {
    setNotification(prevData => ({
      ...prevData,
      SelectedOptions: selectedOptions,
    }))
  }

  //* handle add Notification
  const handleAddNotification = async data => {
    try {
      await createNotification(data)
      const form = document.getElementById("myForm")
      const checkboxes = form.querySelectorAll('input[type="radio"]:checked')
      checkboxes.forEach(checkbox => {
        checkbox.checked = false
      })
      fetchAllNotifications()
      setSave(true)
      toggle()
      notification.SelectedOptions = []
      return true
    } catch (error) { }
  }

  const addButtonClick = () => {
    toggle()
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setForm({})
      notification.SelectedOptions = []
    } else {
      setModal(true)
    }
  }

  const handleSendNotification = async (id) => {
    console.log("Caleedddd....", id);
    try {
      const response = await sentNotification(id)
      if (response) {
        fetchAllNotifications()
      }
      setSave(!save)
    } catch (error) {
      console.log("Error", error);
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Notification Type",
        accessor: "deviceType",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Title",
        accessor: "notificationData.title",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },

      {
        Header: "Body",
        accessor: "notificationData.body",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Topic Name",
        accessor: "topicName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Sent Status",
        accessor: "isSent",
        filterable: true,
        Cell: cellProps => {
          return <ManageBooleanValue {...cellProps} />
        },
      },
      //* Action to sent notification
      //* Sent Button
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Button
                color="primary"
                onClick={() => handleSendNotification(cellProps.row.original._id)}
                disabled={cellProps.row.original.isSent}
              >
                Sent
              </Button>
            </div >
          )
        },
      },
    ],
    []
  )
  //meta title
  document.title = "Notification | React Admin & Notification Template"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Notification" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={notification.allNotification}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    addButtonLabel={"Add Notification"}
                    handleAddButtonClick={addButtonClick}
                    customPageSize={100}
                    className="custom-header-css"
                    dataFetchLoading={loading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Add Notification</ModalHeader>
            <ModalBody>
              <Formik
                enableReinitialize={true}
                initialValues={form}
                onSubmit={async (values, { resetForm }) => {
                  if (values?.notificationType === "user") {
                    values.userId = (notification.SelectedOptions.map(
                      item => item.id
                    ))[0]
                  } else {
                    values.isTopicNotification = true
                  }

                  const response = await handleAddNotification(values)
                  if (response) {
                    resetForm()
                  }
                }}
              >
                {({
                  touched,
                  errors,
                  values,
                  handleSubmit,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <>
                    <Form
                      id="myForm"
                      onSubmit={e => {
                        e.preventDefault()
                        handleSubmit(e)
                        return false
                      }}
                    >
                      <Row>
                        <Col>
                          <Row className="mb-3">
                            <Col md={12} sm={12} xs={12}>
                              <Label className="form-label">
                                Notification Type{" "}
                              </Label>
                              <br />
                              <Input
                                name="notificationType"
                                type="radio"
                                value={"user"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              ></Input>
                              <Label className="mx-3">User Wise</Label>

                              <Input
                                name="notificationType"
                                type="radio"
                                value={"topic"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              ></Input>
                              <Label className="mx-3">Topic Wise</Label>
                            </Col>
                          </Row>

                          {/* User Wise dropdown */}
                          {values?.notificationType == "user" && (
                            <Row className="mb-3">
                              <Col>
                                <Label>User</Label>
                                <Select
                                  options={notification.user}
                                  placeholder="Select user"
                                  isMulti
                                  value={notification.SelectedOptions}
                                  onChange={handleSelectChange}
                                />
                              </Col>
                            </Row>
                          )}

                          {/* topic wise text field */}

                          {values?.notificationType == "topic" && (
                            <Row className="mb-3">
                              <Col>
                                <Label>Topic Name</Label>
                                <Input
                                  type="text"
                                  name="topicName"
                                  placeholder="Enter topic"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.topicName}
                                />
                              </Col>
                            </Row>
                          )}

                          <Row>
                            <Col className="my-2">
                              <Label className="form-label">
                                Notification Title{" "}
                              </Label>
                              <Input
                                name="notificationData.title"
                                type="text"
                                placeholder="Enter Notification Title"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.notificationData?.title || ""}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-12 mt-2">
                              <div className="mb-3">
                                <Label className="form-label">
                                  Notification Body
                                </Label>
                                <br />
                                <Input
                                  name="notificationData.body"
                                  placeholder="Enter notification body..."
                                  type="textarea"
                                  style={{ width: "100%", height: "130px" }}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.notificationData?.body || ""}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-12">
                              <div className="mb-3">
                                <Label className="form-label">Device</Label>
                                <br />
                                <Input
                                  name="deviceType"
                                  type="select"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.deviceType || ""}
                                >
                                  <option>
                                    Select Device
                                  </option>
                                  <option value={"android"}>Android</option>
                                  <option value={"ios"}>Ios</option>
                                </Input>
                              </div>
                            </Col>
                          </Row>

                          <Row className="mb-3 text-center">
                            <Col>
                              <div className="text-center">
                                <Button
                                  size="md"
                                  color="danger"
                                  type="button"
                                  // disabled={submitLoading}
                                  className="mx-2"
                                  onClick={toggle}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  type="submit"
                                  className="btn btn-success save-user"
                                >
                                  Save
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </>
                )}
              </Formik>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Notification
