export const BILLING_CYCLE_DAYS = [
  {
    label: "Days",
    value: "days",
  },
  {
    label: "Months",
    value: "months",
  },
  {
    label: "Years",
    value: "years",
  },
]
