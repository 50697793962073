import Breadcrumb from "components/Common/Breadcrumb"
import TableContainer from "components/Common/TableContainer"
import { Formik, validateYupSchema } from "formik"
import {
  createAnnouncement,
  getAllAnnouncement,
} from "helpers/backendHelpers/announcement"
import { getAllUser } from "helpers/backendHelpers/user"
import { SimpleStringValue } from "helpers/common_helper_functions"
// import PieChart from "pages/Charts/SplineArea";
import React, { useEffect, useMemo, useState } from "react"
import { Container, Modal, ModalHeader, ModalBody } from "reactstrap"

import Select from "react-select"

import {
  Col,
  Row,
  Form,
  Input,
  Label,
  Card,
  CardBody,
  Button,
} from "reactstrap"

const Announcement = () => {
  const [announcement, setAnnouncement] = useState({
    user: [],
    allAnnouncement: [],
    SelectedOptions: [],
  })
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({})
  const [save, setSave] = useState(false)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    fetchAllAnnouncements()
  }, [save])

  //* fetch all announcements
  const fetchAllAnnouncements = async () => {
    try {
      setLoading(true)
      const response = await getAllAnnouncement()
      setAnnouncement(prevData => ({
        ...prevData,
        allAnnouncement: response?.payload?.data,
      }))
      setLoading(false)

      //* get all user
      const user = await getAllUser()
      const finalUserData = user?.payload?.data.map(item => {
        return (item = {
          value: item.userName,
          label: item.userName,
          id: item._id,
        })
      })
      setAnnouncement(prevData => ({
        ...prevData,
        user: finalUserData,
      }))
    } catch (error) {
      setLoading(false)
    }
  }

  //* handle select on change in user
  const handleSelectChange = selectedOptions => {
    setAnnouncement(prevData => ({
      ...prevData,
      SelectedOptions: selectedOptions,
    }))
  }

  //* handle add Announcement
  const handleAddAnnouncement = async data => {
    try {
      await createAnnouncement(data)
      const form = document.getElementById("myForm")
      const checkboxes = form.querySelectorAll('input[type="radio"]:checked')
      checkboxes.forEach(checkbox => {
        checkbox.checked = false
      })
      setSave(true)
      toggle()
      announcement.SelectedOptions = []
      return true
    } catch (error) {}
  }

  const addButtonClick = () => {
    toggle()
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setForm({})
      announcement.SelectedOptions = []
    } else {
      setModal(true)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Announcement Type",
        accessor: "announcementType",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Category",
        accessor: "category",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Title",
        accessor: "title",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Topic Name",
        accessor: "topicName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Body",
        accessor: "body",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
    ],
    []
  )
  //meta title
  document.title = "Announcement | React Admin & Announcement Template"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Announcement" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={announcement.allAnnouncement}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    addButtonLabel={"Add Announcement"}
                    handleAddButtonClick={addButtonClick}
                    customPageSize={100}
                    className="custom-header-css"
                    dataFetchLoading={loading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Add Announcement</ModalHeader>
            <ModalBody>
              <Formik
                enableReinitialize={true}
                initialValues={form}
                onSubmit={async (values, { resetForm }) => {
                  if (values?.announcementType === "user") {
                    values["tokens"] = announcement.SelectedOptions.map(
                      item => item.id
                    )
                  }

                  const response = await handleAddAnnouncement(values)
                  if (response) {
                    resetForm()
                  }
                }}
              >
                {({
                  touched,
                  errors,
                  values,
                  handleSubmit,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <>
                    <Form
                      id="myForm"
                      onSubmit={e => {
                        e.preventDefault()
                        handleSubmit(e)
                        return false
                      }}
                    >
                      <Row>
                        <Col>
                          <Row className="mb-3">
                            <Col md={12} sm={12} xs={12}>
                              <Label className="form-label">
                                Notification Type{" "}
                              </Label>
                              <br />
                              <Input
                                name="announcementType"
                                type="radio"
                                value={"user"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              ></Input>
                              <Label className="mx-3">User Wise</Label>

                              <Input
                                name="announcementType"
                                type="radio"
                                value={"topic"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              ></Input>
                              <Label className="mx-3">Topic Wise</Label>
                            </Col>
                          </Row>

                          {/* User Wise dropdown */}
                          {values?.announcementType == "user" && (
                            <Row className="mb-3">
                              <Col>
                                <Label>User</Label>
                                <Select
                                  options={announcement.user}
                                  placeholder="Select user"
                                  isMulti
                                  value={announcement.SelectedOptions}
                                  onChange={handleSelectChange}
                                />
                              </Col>
                            </Row>
                          )}

                          {/* topic wise text field */}

                          {values?.announcementType == "topic" && (
                            <Row className="mb-3">
                              <Col>
                                <Label>Topic Name</Label>
                                <Input
                                  type="text"
                                  name="topicName"
                                  placeholder="Enter topic"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.topicName}
                                />
                              </Col>
                            </Row>
                          )}

                          <Row>
                            <Col className="my-2">
                              <Label className="form-label">
                                Notification Title{" "}
                              </Label>
                              <Input
                                name="title"
                                type="text"
                                placeholder="Enter Notification Title"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title || ""}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-12 mt-2">
                              <div className="mb-3">
                                <Label className="form-label">
                                  Notification Body
                                </Label>
                                <br />
                                <Input
                                  name="body"
                                  placeholder="Enter notification body..."
                                  type="textarea"
                                  style={{ width: "100%", height: "130px" }}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.body || ""}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-12">
                              <div className="mb-3">
                                <Label className="form-label">Category</Label>
                                <br />
                                <Input
                                  name="category"
                                  type="select"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.category || ""}
                                >
                                  <option>
                                    Select Category
                                  </option>
                                  <option value={"maintenance"}>
                                    Maintenance
                                  </option>
                                  <option value={"general"}>General</option>
                                  <option value={"premium"}>Premium</option>
                                  <option value={"offer"}>Offer</option>
                                </Input>
                              </div>
                            </Col>
                          </Row>

                          <Row className="mb-3 text-center">
                            <Col>
                              <div className="text-center">
                                <Button
                                  size="md"
                                  color="danger"
                                  type="button"
                                  // disabled={submitLoading}
                                  className="mx-2"
                                  onClick={toggle}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  type="submit"
                                  className="btn btn-success save-user"
                                >
                                  Save
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </>
                )}
              </Formik>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Announcement
