import { getApiConfig } from "../authHelper"

import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

export const getAllPlans = () => {
  return get(`${url.PLAN}?sortOrder=-1`, getApiConfig())
}

export const getPlan = id => {
  return get(`${url.PLAN}?_id=${id}`, getApiConfig())
}

export const createPlan = data => {
  return post(`${url.PLAN}`, data, getApiConfig())
}

export const updatePlan = (id, data) => {
  return put(`${url.PLAN}/${id}`, data, getApiConfig())
}

export const deletePlan = id => {
  return del(`${url.PLAN}/${id}`, getApiConfig())
}
