import dayjs from "dayjs"

export const SimpleStringValue = cell => {
  return cell.value || "-"
}

export const SimpleNumberValue = cell => {
  return cell.value || 0
}

export const FreePaidValue = cell => {
  return cell.value ? "Free" : "Paid" || ""
}

export const ArrayToStringValue = cell => {
  return cell.value.toString() || ""
}

export const formatDate = (date, format = "DD/MM/YYYY") => {
  date ? dayjs(date).format(format) : ""
}

export const stringToLowerCase = (str = "") => {
  return str.toLowerCase()
}

export const ManageBooleanValue = cell => {
  return cell.value ? "True" : "False"
}

export const ConvertToHtml = cell => {
  // Create a new div element
  var tempDivElement = document.createElement("div")

  // Set the HTML content with the given value
  tempDivElement.innerHTML = cell.value

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || ""
}
