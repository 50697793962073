import { getApiConfig } from "../authHelper"

import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

export const getAllNotification = () => {
  return get(`${url.NOTIFICATION}?sortOrder=-1`, getApiConfig())
}

export const createNotification = data => {
  return post(`${url.NOTIFICATION}`, data, getApiConfig())
}

export const sentNotification = id => {
  return post(`${url.NOTIFICATION}/send/${id}`, {}, getApiConfig())
}
