import Breadcrumb from "components/Common/Breadcrumb"
import TableContainer from "components/Common/TableContainer"
import { SimpleStringValue } from "helpers/common_helper_functions"
import React, { useEffect, useMemo, useState } from "react"
import { Col, Row, Card, CardBody, Container, Badge } from "reactstrap"
import dayjs from "dayjs"
import { DateRangePicker } from "rsuite"
import "rsuite/dist/rsuite.min.css"
import { getUserInfo } from "helpers/authHelper"
import { getReport } from "helpers/backendHelpers/manageSubscription"

const Index = () => {
  const [report, setReport] = useState([])
  const [reportList, setReportList] = useState([])
  const [date, setDate] = useState({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z",
    endDate: dayjs().endOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z",
  })

  useEffect(() => {
    fetchReport(date.startDate, date.endDate)
  }, [])

  const fetchReport = async (startDate, endDate) => {
    try {
      let reports = await getReport(startDate, endDate)
      reports = reports?.payload?.data
      setReportList(reports)
      reports = reports.map(val => {
        return {
          "Invoice No": val?.invoiceNo,
          "Invoice Date": new Date(val?.createdAt).toLocaleDateString(),
          "Customer Name": val?.userDetails?.name,
          "Firm Name": val?.userDetails?.firmName,
          "GST NO.": val?.userDetails?.GSTNo,
          "Plan Name": val?.name,
          "Plan Amount": val.planAmount,
          "Discount Amount": val.discountAmount,
          "Taxable Amount": val.discountAmount ? parseInt(val.planAmount - val.discountAmount) : val.planAmount,
          "Tax Amount": val.taxAmount,
          "Total Amount": val.finalAmount,
        }
      })
      setReport(reports)
    } catch (error) {
      console.error(error)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Invoice No",
        accessor: "invoiceNo",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Invoice Date",
        accessor: "createdAt",
        filterable: true,
        Cell: cellProps => {
          return dayjs(cellProps.row.original["createdAt"]).format("DD/MM/YYYY")
        },
      },
      {
        Header: "Customer Name",
        accessor: "userDetails.name",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Firm Name",
        accessor: "userDetails.firmName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },

      {
        Header: "GST NO.",
        accessor: "userDetails.GSTNo",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Plan Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Payment Status",
        accessor: "paymentStatus",
        filterable: true,
        Cell: cellProps => {
          const data = cellProps.row.original
          return (
            <Badge color={data.paymentStatus == "paid" ? "success" : "warning"}>
              {data.paymentStatus}
            </Badge>
          )
        },
      },
      {
        Header: "Plan Amount",
        accessor: "planAmount",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Discount Amount",
        accessor: "discountAmount",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      //* taxable amount = plan amount - discount amount
      {
        Header: "Taxable Amount",
        filterable: true,
        Cell: cellProps => {
          let data = cellProps.row.original
          if (data.discountAmount) {
            return <SimpleStringValue value={parseInt(data.planAmount - data.discountAmount)} />
          }
          return <SimpleStringValue value={data.planAmount} />
        }      
      },
      {
        Header: "Tax Amount",
        accessor: "taxAmount",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Total Amount",
        accessor: "finalAmount",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
    ],
    []
  )

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Report" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <div style={{ position: "absolute", left: "35%", zIndex: "1" }}>
                  <DateRangePicker
                    onClean={() => {
                      fetchReport()
                      setDate({
                        startDate:
                          dayjs(getUserInfo().createdAt).format("YYYY-MM-DD") +
                          "T00:00:00.000Z",
                        endDate:
                          dayjs().format("YYYY-MM-DD") + "T23:59:59.000Z",
                      })
                    }}
                    defaultValue={[
                      new Date(date.startDate),
                      new Date(date.endDate),
                    ]}
                    placeholder="selected Date"
                    className="range-picker mb-2 date-picker-div"
                    onChange={e => {
                      if (e !== null) {
                        let [startDate, endDate] = e
                        startDate =
                          dayjs(startDate).format("YYYY-MM-DD").split("T")[0] +
                          "T00:00:00.000Z"
                        endDate =
                          dayjs(endDate).format("YYYY-MM-DD").split("T")[0] +
                          "T00:00:00.000Z"
                        setDate({
                          startDate: startDate,
                          endDate: endDate,
                        })
                        fetchReport(startDate, endDate)
                      }
                    }}
                    showOneCalendar
                    format="dd/MM/yyyy"
                  />
                </div>

                <TableContainer
                  columns={columns}
                  data={reportList}
                  isGlobalFilter={true}
                  isAddOptions={true}
                  csvFileName={`Bill-Saathi-${dayjs(date.startDate).format(
                    "DD/MM/YYYY"
                  )} - ${dayjs(date.endDate).format("DD/MM/YYYY")}`}
                  canExportCsv={true}
                  csvData={report}
                  customPageSize={100}
                  className="custom-header-css"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Index
