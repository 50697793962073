import Breadcrumb from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import TableContainer from "components/Common/TableContainer"
import SubmitLoader from "components/Common/submitLoader"
import dayjs from "dayjs"
import { useFormik } from "formik"
import { getAllPlans } from "helpers/backendHelpers/plan"
import {
  createExpenseCategory,
  deleteExpenseCategory,
  getAllExpenseCategory,
  updateExpenseCategory,
} from "helpers/backendHelpers/manageExpenseCategory"
import { getAllUser } from "helpers/backendHelpers/user"
import { SimpleStringValue } from "helpers/common_helper_functions"
import React, { useEffect, useMemo, useState } from "react"
//Import Flatepicker
import { Link } from "react-router-dom"
import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Container,
  Button,
  InputGroup,
} from "reactstrap"
import * as Yup from "yup"
import ExpenseViewModal from "./ViewModal"

const ExpenseCategory = props => {
  const [allExpense, setAllExpense] = useState([])
  const [loading, setLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [save, setSave] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [form, setForm] = useState({
    name: "",
    placeHolderText: "",
  })

  useEffect(() => {
    document.title = "ExpenseCategory |  React Admin & ExpenseCategory Template"

    fetchAllExpenseCategory()
  }, [save])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: form,
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter plan name"),
      userId: Yup.string().required("Please Enter User name"),
    }),
    onSubmit: values => {
      values["planAmount"] = expenses.selectPlanData.planAmount
      if (isEdit) {
        return handleUpdateExpenseCategory(form._id, values)
      } else {
        return handleAddExpenseCategory(values)
      }
    },
  })

  //* fetch all ExpenseCategory
  const fetchAllExpenseCategory = async () => {
    try {
      setLoading(true)
      const response = await getAllExpenseCategory()
      setAllExpense(response?.payload?.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  //* handle create manage expense
  const handleAddExpenseCategory = async data => {
    try {
      setSubmitLoading(true)
      await createExpenseCategory(data)

      setSubmitLoading(false)
      setSave(!save)
      toggle()
    } catch (error) {
      setSubmitLoading(false)
    }
  }

  //* update manage expense
  const handleUpdateExpenseCategory = async (id, data) => {
    try {
      setSubmitLoading(true)
      await updateExpenseCategory(id, data)

      setSubmitLoading(false)
      setSave(!save)
      toggle()
    } catch (error) {
      setSubmitLoading(false)
    }
  }

  //* handle add click
  const handleAddButtonClick = () => {
    setIsEdit(false)
    toggle()
  }

  //* open delete modal
  const onClickDelete = Data => {
    setForm(Data)
    setDeleteModal(true)
  }

  //* handle Delete plan
  const handleDeleteExpenseCategory = async () => {
    try {
      await deleteExpenseCategory(form._id)

      setSave(!save)
      setForm({})
      setDeleteModal(false)
    } catch (error) {}
  }

  //* handle update status
  const handleUpdateExpenseCategoryStatus = async (id, data) => {
    try {
      setLoading(true)
      await updateExpenseCategory(id, data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  //* close view modal
  const toggle = () => {
    if (modal) {
      setModal(false)
      setForm({
        userId: "",
        name: "",
      })

      validation.resetForm()
    } else {
      setModal(true)
    }
  }

  //* toggle view modal
  const toggleViewModal = () => {
    if (modal1 == false) {
      setModal1(true)
    } else {
      setModal1(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Category name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },

      {
        Header: "Place Holder",
        accessor: "placeholderText",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },

      {
        Header: "Date",
        accessor: "createdAt",
        filterable: true,
        Cell: cellProps => {
          const data = cellProps.row.original
          return <span>{new Date(data?.createdAt).toLocaleDateString()}</span>
        },
      },

      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-primary"
                onClick={() => {
                  setForm(cellProps?.row?.original)
                  toggleViewModal()
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  setForm(cellProps.row.original)
                  setExpenseCategory(preData => ({
                    ...preData,
                    selectPlanData: cellProps.row.original,
                  }))
                  setIsEdit(true)
                  toggle()
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <ExpenseViewModal isOpen={modal1} toggle={toggleViewModal} data={form} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteExpenseCategory}
        onCloseClick={() => {
          setDeleteModal(false)
          setForm({})
        }}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Manage ExpenseCategory" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={allExpense}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    addButtonLabel="Add Manage ExpenseCategory"
                    handleAddButtonClick={handleAddButtonClick}
                    dataFetchLoading={loading}
                    customPageSize={100}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/*  */}
          <Modal isOpen={modal} toggle={toggle}>
            {submitLoading ? <SubmitLoader /> : <></>}
            <ModalHeader toggle={toggle} tag="h4">
              {isEdit
                ? "Edit Manage ExpenseCategory"
                : "Add Manage ExpenseCategory"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        Category Name<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="name"
                        type="select"
                        onChange={val => {
                          const data = JSON.parse(val.target.value)
                          setForm({
                            ...validation.values,
                            name: data._id,
                          })
                        }}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />

                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        size="md"
                        color="danger"
                        type="button"
                        // disabled={submitLoading}
                        className="mx-2"
                        onClick={() => {
                          toggle()
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        {isEdit ? "Update" : "Save"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ExpenseCategory
