import { getApiConfig } from "../authHelper"

import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

export const getAllExpenseCategory = () => {
  return get(url.EXPENSE_CATEGORY, getApiConfig())
}

export const getExpenseCategory = id => {
  return get(`${url.EXPENSE_CATEGORY}?_id=${id}`, getApiConfig())
}

export const createExpenseCategory = data => {
  return post(`${url.EXPENSE_CATEGORY}`, data, getApiConfig())
}

export const updateExpenseCategory = (id, data) => {
  return put(`${url.EXPENSE_CATEGORY}/${id}`, data, getApiConfig())
}

export const deleteExpenseCategory = id => {
  return del(`${url.EXPENSE_CATEGORY}/${id}`, getApiConfig())
}
