import Breadcrumb from "components/Common/Breadcrumb"
// import PieChart from "pages/Charts/SplineArea";
import React, { useEffect, useState } from "react"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  InputGroup,
  Row,
} from "reactstrap"
//Import Flatepicker
import Flatpickr from "react-flatpickr"
import dayjs from "dayjs"
import {
  getAllDashboard,
  getAllDateWiseData,
} from "helpers/backendHelpers/dashboard"
import SubmitLoader from "components/Common/submitLoader"

const Dashboard = () => {
  const [allAnalytics, setAllAnalytics] = useState({})
  const [loading, setLoading] = useState(false)
  const [isHandleChange, setIsHandleChange] = useState(true)
  useEffect(() => {
    document.getElementById("dashboard").classList.add("mm-active")
    document.title = "Dashboard | React Admin & Dashboard Template"

    fetchAllAnalytics()
  }, [])

  //* fetch all analytics
  const fetchAllAnalytics = async () => {
    try {
      setLoading(true)
      const response = await getAllDashboard()
      setAllAnalytics(response?.payload)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  //* handle change in date flatpicker
  const getDateWiseData = async data => {
    let [startDate, endDate] = data
    startDate = new Date(startDate).toISOString()
    endDate = new Date(endDate).toISOString()

    try {
      setLoading(true)
      let response = await getAllDateWiseData(startDate, endDate)
      response = response?.payload
      response["date"] = `${new Date(
        startDate
      ).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}`
      setAllAnalytics(response)
      setIsHandleChange(false)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  //* card data
  const reports = [
    {
      title: "Total user",
      iconClass: "dripicons-user-group",
      count: allAnalytics?.totalMetrics?.userCount,
    },
    {
      title: "Total Payment",
      iconClass: "fas fa-money-bill-alt",
      count: allAnalytics?.totalMetrics?.totalPayment,
    },
    {
      title: "Total User",
      iconClass: "dripicons-user-group",
      count: allAnalytics?.rangeWiseMetrics?.userCount,
    },
    {
      title: "Total Payment",
      iconClass: "fas fa-money-bill-alt",
      count: allAnalytics?.rangeWiseMetrics?.totalPayment,
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h4 className="font-size-18">DASHBOARD</h4>
            <InputGroup style={{ width: "280px", marginRight: "200px" }}>
              <Flatpickr
                className="form-control d-block "
                placeholder="Select Date ..."
                name="expirationDate"
                options={{
                  allowInput: true,
                  altFormat: "F j, Y",
                  mode: "range",
                  dateFormat: "Y-m-d",
                }}
                onChange={e => {
                  getDateWiseData(e)
                }}
              />
            </InputGroup>
          </div>
          {loading ? <SubmitLoader /> : <></>}
          <Row>
            {/* Reports Render */}
            {reports.map((report, key) => (
              <Col md="3" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="d-flex">
                          <p className="text-muted fw-medium">{report.title}</p>
                          <p
                            className="mb-0 mx-2"
                            style={{
                              fontSize: "12px",
                              color: "red",
                              textDecoration: "underline",
                            }}
                          >
                            {isHandleChange
                              ? "Last 30 days"
                              : allAnalytics?.date}
                          </p>
                        </div>
                        <h4 className="mb-0">
                          {report.count ? report.count : 0}
                        </h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className={report.iconClass + " font-size-24"}></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
