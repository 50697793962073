import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Button, Col, Row, Card, CardBody } from "reactstrap"
import "react-image-lightbox/style.css"

const ViewDetails = props => {
  return (
    <React.Fragment>
      <Row className="mt-3">
        <Col className="text-end">
          <Button
            color="info"
            onClick={() => {
              props.history.push("/plan")
            }}
          >
            Back
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} className="mb-3">
          <Card>
            <Row className="no-gutters align-items-center">
              <Col md={12}>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <table className="table table-striped advocate">
                        <tbody>
                          <tr>
                            <th scope="col">Name</th>
                            <td>{props?.plans.name}</td>
                          </tr>
                          <tr>
                            <th scope="col">Plan Amount</th>
                            <td>{props?.plans.planAmount}</td>
                          </tr>
                          <tr>
                            <th scope="col">Listing Amount</th>
                            <td>{props?.plans.listingAmount}</td>
                          </tr>
                          <tr>
                            <th scope="col">Category</th>
                            <td>{props?.plans.planCategory}</td>
                          </tr>
                          <tr>
                            <th scope="col">Number Of Allowed Gst</th>
                            <td>{props?.plans.noOfAllowedGST}</td>
                          </tr>

                          <tr>
                            <th scope="col">Billing Cycle</th>
                            <td>{`${props?.plans.noOfBillingCycle} ${props?.plans.billingCycle}`}</td>
                          </tr>
                          <tr>
                            <th scope="col">Description</th>
                            <td>{props?.plans.description}</td>
                          </tr>
                          <tr>
                            <th scope="col">Features</th>
                            <td>
                              {props?.plans.features.map(item => (
                                <>
                                  <span>{`${item} , `}</span>
                                </>
                              ))}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </CardBody>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

ViewDetails.propTypes = {
  AdvocatesData: PropTypes.object.isRequired,
}

export default ViewDetails
