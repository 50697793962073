import axios from "axios"
import { API_URL } from "./url_helper"
import { getAuthToken } from "./authHelper"
import { SaveToast } from "components/Common/SaveToast"

//pass new generated access token here
const token = getAuthToken()

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  try {
    const response = await axiosApi.get(url, { ...config })
    return response.data
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 409) {
      localStorage.clear()
      window.location.href = "/login"
    }
    const message = error.response.data.message
    SaveToast({ message, type: "error" })
    throw error
  }
}

export async function post(url, data, config = {}) {
  try {
    let response = await axiosApi.post(url, { ...data }, { ...config })
    response = response.data
    const message = response.message
    SaveToast({ message, type: "success" })
    return response
  } catch (error) {
    const message = error.response.data.message
    SaveToast({ message, type: "error" })
    throw error
  }
}

export async function put(url, data, config = {}) {
  try {
    let response = await axiosApi.put(url, { ...data }, { ...config })
    response = response.data
    const message = response.message
    SaveToast({ message, type: "success" })
    return response
  } catch (error) {
    const message = error.response.data.message
    SaveToast({ message, type: "error" })
    throw error
  }
}

export async function del(url, config = {}) {
  try {
    let response = await axiosApi.delete(url, { ...config })
    response = response.data
    const message = response.message
    SaveToast({ message, type: "success" })
    return response
  } catch (error) {
    const message = error.response.data.message
    SaveToast({ message, type: "error" })
    throw error
  }
}
