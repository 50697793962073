import { getApiConfig } from "../authHelper"

import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

export const getAllDashboard = () => {
  return get(url.DASHBOARD, getApiConfig())
}

export const getAllDateWiseData = (startDate, endDate) => {
  return get(
    `${url.DASHBOARD}?startDate=${startDate}&endDate=${endDate}`,
    getApiConfig()
  )
}
