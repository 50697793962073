import { getApiConfig } from "../authHelper"

import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

export const getAllAnnouncement = () => {
  return get(`${url.ANNOUNCEMENT}?sortOrder=-1`, getApiConfig())
}

export const createAnnouncement = data => {
  return post(`${url.ANNOUNCEMENT}`, data, getApiConfig())
}
