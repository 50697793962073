import Breadcrumb from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import TableContainer from "components/Common/TableContainer"
import SubmitLoader from "components/Common/submitLoader"
import { useFormik } from "formik"

import { SimpleStringValue } from "helpers/common_helper_functions"
import { MdVerified } from "react-icons/md"
import React, { useEffect, useMemo, useState } from "react"
//Import Flatepicker
import classnames from "classnames"
import { MdClose } from "react-icons/md"

import { Link } from "react-router-dom"
import Flatpickr from "react-flatpickr"
import copy from "clipboard-copy"
import { MdFileCopy } from "react-icons/md"
import Select from "react-select"
import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Container,
  Button,
  Badge,
  TabPane,
  NavItem,
  NavLink,
} from "reactstrap"
import * as Yup from "yup"
import {
  createSubscriptionPlan,
  deleteSubscriptionEnterprise,
  getAllSubscriptionEnterprise,
  getInquiry,
  updatePaymentStatus,
  updateSubscriptionEnterprise,
} from "helpers/backendHelpers/manageEnterprise"
import { getAllUser } from "helpers/backendHelpers/user"
import { getAllPlans } from "helpers/backendHelpers/plan"
import dayjs from "dayjs"

const ManagePlan = props => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [save, setSave] = useState(false)
  const [planId, setPlanId] = useState("")
  const [heading, setHeading] = useState("")
  const [activeTab, setActiveTab] = useState("1")
  const [isEdit, setIsEdit] = useState(false)
  const [store, setStore] = useState({
    plan: [],
    user: [],
    planCategory: {},
  })
  const [dropdownVal, setDropdownVal] = useState("")

  const [form, setForm] = useState({})

  useEffect(() => {
    document.title =
      "SubscriptionEnterprise |  React Admin & SubscriptionEnterprise Template"
    fetchInquiry()
  }, [save])

  useEffect(() => {
    if (activeTab === "1") {
      setDropdownVal("")
      setHeading("inquiry")
    } else {
      setDropdownVal("")
      setHeading("Custom Plans")
    }
  }, [activeTab])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: form,
    validationSchema: Yup.object({
      planAmount: Yup.number().required("plan amount is required"),
      noOfAllowedUsers: Yup.number().required("no of allowed user is required"),
      noOfAllowedGST: Yup.number().required("no of allowed gst is required"),
    }),
    onSubmit: values => {
      if (isEdit) {
        return handleEditEnterprisePlan(planId, values)
      } else {
        return handleAddPlan(values)
      }
    },
  })

  console.log(form, "form")

  //* fetch all SubscriptionEnterprises
  const fetchInquiry = async status => {
    try {
      setLoading(true)
      const response = await getInquiry(status)
      setData(response.payload.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAllPlanAndUser()
  }, [])

  //* fetch all plan and user
  const fetchAllPlanAndUser = async () => {
    try {
      let user = await getAllUser()
      user = user.payload.data.map(item => {
        return {
          _id: item._id,
          value: item.userName,
          label: item.userName,
        }
      })

      let plan = await getAllPlans()
      //* create nested plan and plan category
      plan = plan.payload.data.map((val, i) => {
        return {
          value: val.name,
          label: val.name,
          options: val.planCategories.map(item => {
            return {
              _id: item._id,
              ...(val.name !== "RISE Plan"
                ? {
                    value: `${item.noOfBillingCycle} ${item.billingCycle}`,
                    label: `${item.noOfBillingCycle} ${item.billingCycle}`,
                    noOfBillingCycle: item.noOfBillingCycle,
                    billingCycle: item.billingCycle,
                    planAmount: item.planAmount,
                    planName: val.name,
                    noOfAllowedUsers: item.noOfAllowedUsers,
                    noOfAllowedGST: item.noOfAllowedGST,
                    expirationDate: dayjs()
                      .add(item.noOfBillingCycle, item.billingCycle)
                      .format("DD-MM-YYYY"),
                  }
                : {
                    value: "Custom",
                    label: "Custom",
                    noOfBillingCycle: 0,
                    billingCycle: "",
                    planAmount: 0,
                    planName: val.name,
                    noOfAllowedUsers: 0,
                    noOfAllowedGST: 0,
                    expirationDate: "",
                  }),
            }
          }),
        }
      })

      setStore({
        ...store,
        user: user,
        plan: plan,
      })
    } catch (error) {}
  }

  const handleAddPlan = async data => {
    try {
      setSubmitLoading(true)
      await createSubscriptionPlan(data)
      setSave(preSave => !preSave)
      toggle()
      setSubmitLoading(false)
    } catch (error) {
      setSubmitLoading(false)
    }
  }

  //* update manage subscription
  const handleEditEnterprisePlan = async (id, data) => {
    try {
      setSubmitLoading(true)
      await updateSubscriptionEnterprise(id, data)
      setSubmitLoading(false)
      setSave(preSave => !preSave)
      toggle()
    } catch (error) {
      setSubmitLoading(false)
    }
  }

  //* open delete modal
  const onClickDelete = data => {
    setPlanId(data._id)
    setDeleteModal(true)
  }

  //* handle Delete plan
  const handleDeleteSubscriptionEnterprise = async () => {
    try {
      await deleteSubscriptionEnterprise(planId)
      setSave(!save)
      setForm({})
      setDeleteModal(false)
    } catch (error) {}
  }

  //* handle update payment status
  const handleUpdatePaymentStatus = async (id, data) => {
    try {
      setLoading(true)
      await updatePaymentStatus(id, data)
      setSave(!save)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  //* close view modal
  const toggle = () => {
    if (modal) {
      setModal(false)
      setForm({
        noOfAllowedUsers: "",
        planAmount: "",
        noOfAllowedGST: "",
      })

      validation.resetForm()
    } else {
      setModal(true)
    }
  }

  //* handle add click
  const handleAddClick = () => {
    setIsEdit(false)
    setForm({
      planAmount: "",
      userId: "",
      noOfBillingCycle: "",
      billingCycle: "",
      planAmount: "",
      noOfAllowedUsers: "",
      noOfAllowedGST: "",
      expirationDate: "",
      isGSTApplied: true,
    })
    toggle()
  }

  //* toggle tab inquiry and plan
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  //* plan column
  const planColumn = useMemo(
    () => [
      {
        Header: "User Name",
        accessor: "userId.userName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Mobile Number",
        accessor: "userId.mobileNo",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "No. of allowed gst",
        accessor: "noOfAllowedGST",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Allowed users",
        accessor: "noOfAllowedUsers",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Payment Link",
        accessor: "paymentLink",
        filterable: true,
        Cell: cellProps => {
          const data = cellProps.row.original
          return (
            <>
              {data.paymentLink ? (
                <>
                  <UncontrolledTooltip placement="top" target="copyTooltip">
                    Copy
                  </UncontrolledTooltip>

                  <MdFileCopy
                    id="copyTooltip"
                    className="cursor-pointer"
                    size={25}
                    onClick={() => {
                      copy(data.paymentLink)
                      // setIsCopy({ [data._id]: true })
                    }}
                  />
                </>
              ) : (
                <MdClose size={25} color="red" />
              )}
            </>
          )
        },
      },
      {
        Header: "Plan Amount",
        accessor: "planAmount",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Expiry Date",
        accessor: "subscriptionId.expiryDate",
        filterable: true,
        Cell: cellProps => {
          return (
            dayjs(cellProps?.row?.original?.subscriptionId?.expiryDate).format(
              "DD-MM-YYYY"
            ) || "-"
          )
        },
      },
      {
        Header: "Payment Link Send Status",
        accessor: "sendPaymentStatus",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original
          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input"
                defaultChecked={cellData.isPaymentLinkSend}
                onChange={e => {
                  let { checked, name } = e.target
                  return handleUpdatePaymentStatus(cellData._id, {
                    isPaymentLinkSend: checked,
                  })
                }}
              />
            </div>
          )
        },
      },

      {
        Header: "Payment",
        accessor: "isPaymentCompleted",
        disableFilters: false,
        Cell: cellProps => {
          return cellProps.row.original.isPaymentCompleted ? (
            <MdVerified size={35} style={{ color: "#19c319" }} />
          ) : (
            <Badge color="warning">Pending</Badge>
          )
        },
      },

      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* <Link
                to="#"
                className="text-success"
                onClick={() => {
                  setPlanId(cellProps.row.original._id)
                  setIsEdit(true)
                  const data = cellProps.row.original
                  setForm({
                    userId: data.userId._id,
                    planId: data.planId._id,
                    billingCycle: data.billingCycle,
                    noOfBillingCycle: data.noOfBillingCycle,
                    planAmount: data.planAmount,
                    planName: data.planId.name,
                    noOfAllowedUsers: data.noOfAllowedUsers,
                    noOfAllowedGST: data.noOfAllowedGST,
                  })
                  toggle()
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link> */}
              {cellProps.row.original.isPaymentCompleted === false && (
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    onClickDelete(cellProps.row.original)
                  }}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    disabled
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              )}
            </div>
          )
        },
      },
    ],
    [data]
  )

  //* inquiry column
  const inquiryColumn = useMemo(
    () => [
      {
        Header: "User Name",
        accessor: "userId.userName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Mobile Number",
        accessor: "userId.mobileNo",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "No. of allowed gst",
        accessor: "noOfAllowedGST",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "No. of inquiry",
        accessor: "lastEnquiriesDates",
        filterable: true,
        Cell: cellProps => {
          return cellProps.row.original.lastEnquiriesDates.length
        },
      },
      {
        Header: "Allowed users",
        accessor: "noOfAllowedUsers",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },

      {
        Header: "Plan Amount",
        accessor: "planAmount",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Inquiry",
        accessor: "isPaymentCompleted",
        disableFilters: false,
        Cell: cellProps => {
          return cellProps.row.original.isPaymentCompleted ? (
            <MdVerified size={35} style={{ color: "#19c319" }} />
          ) : (
            <Badge color="warning">Pending</Badge>
          )
        },
      },
    ],
    [data]
  )

  const colorStyles = {
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#0080FF" : null,
        color: isFocused ? "white" : "#333333",
      }
    },
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSubscriptionEnterprise}
        onCloseClick={() => {
          setDeleteModal(false)
          setPlanId("")
        }}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title={heading} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                          fetchInquiry()
                        }}
                      >
                        Inquiry
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                          fetchInquiry()
                        }}
                      >
                        Custom Plans
                      </NavLink>
                    </NavItem>
                    <NavItem className="mx-5 mb-1">
                      <Input
                        type="select"
                        onChange={e => {
                          setDropdownVal(e.target.value)
                          fetchInquiry(e.target.value)
                        }}
                        value={dropdownVal}
                      >
                        <option>Select {heading} </option>
                        <option value="">All {heading}</option>
                        {activeTab == "1" ? (
                          <>
                            <option value="&isPaymentCompleted=true">
                              Close inquires
                            </option>
                            <option value="&isPaymentLinkSend=false">
                              Pending inquires
                            </option>
                          </>
                        ) : (
                          <>
                            <option value="&isPaymentLinkSend=false&isPaymentCompleted=false">
                              Pending payment
                            </option>
                            <option value="&isPaymentCompleted=true">
                              Receive payment
                            </option>
                          </>
                        )}
                      </Input>
                    </NavItem>
                  </ul>

                  {activeTab == "1" ? (
                    <TabPane
                      activeTab={activeTab}
                      tabId="1"
                      id="processing"
                      className="p-3"
                    >
                      <div>
                        <TableContainer
                          columns={inquiryColumn}
                          data={data}
                          isGlobalFilter={true}
                          dataFetchLoading={loading}
                          customPageSize={100}
                          className="custom-header-css"
                        />
                      </div>
                    </TabPane>
                  ) : (
                    <TabPane
                      activeTab={activeTab}
                      tabId="2"
                      id="processing"
                      className="p-3"
                    >
                      <div>
                        <TableContainer
                          columns={planColumn}
                          data={data}
                          isGlobalFilter={true}
                          isAddOptions={true}
                          addButtonLabel={"Add Plan"}
                          handleAddButtonClick={handleAddClick}
                          dataFetchLoading={loading}
                          customPageSize={100}
                          className="custom-header-css"
                        />
                      </div>
                    </TabPane>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/*  */}
          <Modal isOpen={modal} toggle={toggle}>
            {submitLoading ? <SubmitLoader /> : <></>}
            <ModalHeader toggle={toggle} tag="h4">
              {isEdit ? "Update Plan" : "Add Plan"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        User<span className="text-danger">*</span>
                      </Label>

                      <Select
                        options={store.user}
                        value={
                          store.user[
                            store.user.findIndex(
                              val => val._id === validation.values.userId
                            )
                          ]
                        }
                        clearable={true}
                        onChange={e => {
                          setForm({
                            ...validation.values,
                            userId: e._id,
                          })
                        }}
                      />
                      {validation.touched.planAmount &&
                      validation.errors.planAmount ? (
                        <FormFeedback type="invalid">
                          {validation.errors.planAmount}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xxl={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Plan<span className="text-danger">*</span>
                      </Label>

                      <Select
                        name="options"
                        options={store.plan}
                        styles={colorStyles}
                        value={store.plan.find(
                          val => val.value === validation.values.planName
                        )}
                        onChange={value => {
                          setForm({
                            ...validation.values,
                            planId: value._id,
                            billingCycle: value.billingCycle,
                            noOfBillingCycle: value.noOfBillingCycle,
                            planAmount: value.planAmount,
                            planName: value.planName,
                            noOfAllowedUsers: value.noOfAllowedUsers,
                            noOfAllowedGST: value.noOfAllowedGST,
                            expirationDate: value.expirationDate,
                          })
                        }}
                      />
                    </div>
                  </Col>

                  <Col xxl={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        No. Billing Cycle<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        placeholder="Enter No. Billing Cycle"
                        name="noOfBillingCycle"
                        readOnly={form?.planName === "RISE Plan" ? false : true}
                        onChange={validation.handleChange}
                        value={validation.values.noOfBillingCycle}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.noOfBillingCycle &&
                          validation.errors.noOfBillingCycle
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Col>
                  <Col xxl={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Billing Cycle<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="billingCycle"
                        placeholder="Enter Billing Cycle"
                        readOnly={form?.planName === "RISE Plan" ? false : true}
                        onChange={validation.handleChange}
                        value={validation.values.billingCycle}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.billingCycle &&
                          validation.errors.billingCycle
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Col>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        Plan Amount<span className="text-danger">*</span>
                      </Label>

                      <Input
                        name="planAmount"
                        type="number"
                        placeholder="Enter Plan Amount"
                        onChange={validation.handleChange}
                        value={validation.values.planAmount}
                        readOnly={form?.planName === "RISE Plan" ? false : true}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.planAmount &&
                          validation.errors.planAmount
                            ? true
                            : false
                        }
                      ></Input>
                      {validation.touched.planAmount &&
                      validation.errors.planAmount ? (
                        <FormFeedback type="invalid">
                          {validation.errors.planAmount}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* {subscriptions?.selectPlanData && ( */}
                <Row className="mb-3">
                  <Col>
                    <Label>
                      No of Allowed user<span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="number"
                      placeholder="Enter No. of Allowed user"
                      name="noOfAllowedUsers"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.noOfAllowedUsers}
                      invalid={
                        validation.touched.noOfAllowedUsers &&
                        validation.errors.noOfAllowedUsers
                          ? true
                          : false
                      }
                    />
                    {validation.touched.noOfAllowedUsers &&
                    validation.errors.noOfAllowedUsers ? (
                      <FormFeedback type="invalid">
                        {validation.errors.noOfAllowedUsers}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Label>
                      No of allowed gst<span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="number"
                      placeholder="Enter No of allowed gst"
                      name="noOfAllowedGST"
                      onChange={validation.handleChange}
                      value={validation.values.noOfAllowedGST}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.noOfAllowedGST &&
                        validation.errors.noOfAllowedGST
                          ? true
                          : false
                      }
                    />
                    {validation.touched.noOfAllowedGST &&
                    validation.errors.noOfAllowedGST ? (
                      <FormFeedback type="invalid">
                        {validation.errors.noOfAllowedGST}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>

                <Col className="mb-3">
                  <Label>
                    Expiration Date<span className="text-danger">*</span>
                  </Label>
                  <Flatpickr
                    className="form-control d-block "
                    placeholder="Select Date ..."
                    name="expirationDate"
                    options={{
                      allowInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "d-m-y",
                    }}
                    value={validation.values.expirationDate}
                    onChange={e => {
                      setForm({
                        ...validation.values,
                        expirationDate: new Date(e[0]).toISOString(),
                      })
                    }}
                  />
                </Col>

                <Col className="mb-3 text-center">
                  <Label className="d-flex justify-content-center">
                    <Input
                      type="checkbox"
                      style={{ width: "18px", height: "18px" }}
                      className="m-0 mx-2"
                      name="isGSTApplied"
                      defaultChecked={form.isGSTApplied}
                      onChange={e => {
                        setForm({
                          ...validation.values,
                          isGSTApplied: e.target.checked,
                        })
                      }}
                    />
                    GST Applied
                  </Label>
                </Col>

                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        size="md"
                        color="danger"
                        type="button"
                        // disabled={submitLoading}
                        className="mx-2"
                        onClick={() => {
                          toggle()
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        {isEdit ? "Update" : "Save"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ManagePlan
