import { t } from "i18next"
import React from "react"
import { Spinner } from "reactstrap"
const SubmitLoader = () => {
  return (
    <>
      <Spinner
        className="ms-2"
        style={{
          zIndex: "1000",
          width: "3rem",
          height: "3rem",
          margin: "0",
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        color="primary"
      />
    </>
  )
}

export default SubmitLoader
