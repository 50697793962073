import React, { useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import { Form, Formik } from "formik"
import * as Yup from "yup"

// import UpdateModal from "../../components/Common/UpdateModal"
import Breadcrumb from "components/Common/Breadcrumb"
import SubmitLoader from "components/Common/submitLoader"
import { createPlan, getPlan, updatePlan } from "helpers/backendHelpers/plan"
import ViewDetails from "./viewDetails"
import { fileUpload } from "helpers/backendHelpers/fileUpload"
import { BILLING_CYCLE_DAYS } from "util/constant"

const AddEditPlan = props => {
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [form, setForm] = useState({
    name: "",
    description: "",
    planAmount: "",
    listingAmount: "",
    noOfAllowedGST: "",
    noOfBillingCycle: "",
    billingCycle: "",
    planType: "",
    bannerImageUrl: "",
    features: [],
  })

  const { type, id } = props.match.params || {}
  useEffect(() => {
    document.getElementById("plan").classList.add("mm-active")
    switch (type) {
      case "edit":
        setIsEdit(true)
        setIsView(false)
        break
      case "view":
        setIsView(true)
        setIsEdit(false)
        break
      case "add":
        break
      default:
        setIsView(false)
        setIsEdit(false)
        break
    }

    if (id) {
      fetchPlanById(id)
    }
  }, [isEdit, isView])

  //* fetch plan details
  const fetchPlanById = async id => {
    try {
      setSubmitLoading(true)
      const response = await getPlan(id)
      setForm(response?.payload?.data[0])
      setSubmitLoading(false)
    } catch (error) {}
  }

  //* handle add plan
  const handleAddPlan = async data => {
    try {
      setSubmitLoading(true)
      await createPlan(data)
      props.history.push("/plan")
      setSubmitLoading(false)
    } catch (error) {
      setSubmitLoading(false)
    }
  }

  //* handle Edit plan
  const handleEditPlan = async (id, data) => {
    try {
      setSubmitLoading(true)
      await updatePlan(id, data)
      props.history.push("/plan")
      setSubmitLoading(false)
    } catch (error) {
      setSubmitLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {submitLoading ? <SubmitLoader /> : <></>}
          {/* <Breadcrumb breadcrumbItem="plan" /> */}
          <Breadcrumb
            title={`${
              type == "add" ? "Add" : type == "view" ? "View" : "Update"
            } plan`}
          />
          {isView ? (
            <>
              {" "}
              <ViewDetails plans={form} {...props} />{" "}
            </>
          ) : (
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={form}
                  validationSchema={Yup.object({
                    name: Yup.string().required("Please Enter plan Name"),
                    description: Yup.string().required(
                      "Please Enter Description"
                    ),
                    planAmount: Yup.number().required(
                      "Please Enter Plan amount"
                    ),
                    listingAmount: Yup.number().required(
                      "Please Enter Listing Amount"
                    ),
                    planType: Yup.string().required("Please Enter plan Type"),
                    noOfAllowedGST: Yup.number().required(
                      "Please Enter Number Of allowed GST"
                    ),
                    noOfBillingCycle: Yup.number().required(
                      "Please Enter Number Of billing cycle"
                    ),

                    billingCycle: Yup.string().required(
                      "Please Enter billing cycle"
                    ),

                    features: Yup.array().required("Please Enter Features tag"),
                  })}
                  onSubmit={values => {
                    if (isEdit) {
                      handleEditPlan(id, values)
                    } else {
                      handleAddPlan(values)
                    }
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                  }) => (
                    <>
                      <Form>
                        <Row>
                          <Col>
                            <Row className="mb-3">
                              <Row>
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    plan Name{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="name"
                                    type="text"
                                    placeholder="Enter plan name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={touched.name && errors.name}
                                    defaultValue={values.name}
                                  />
                                  {touched.name && errors.name && (
                                    <FormFeedback>{errors.name}</FormFeedback>
                                  )}
                                </Col>

                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Plan Amount{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="planAmount"
                                    type="number"
                                    placeholder="Enter Plan Amount"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.planAmount && errors.planAmount
                                    }
                                    defaultValue={values.planAmount}
                                  />
                                  {touched.planAmount && errors.planAmount && (
                                    <FormFeedback>
                                      {errors.planAmount}
                                    </FormFeedback>
                                  )}
                                </Col>

                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Listing Amount{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="listingAmount"
                                    type="number"
                                    placeholder="Enter Listing Amount"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.listingAmount &&
                                      errors.listingAmount
                                    }
                                    defaultValue={values.listingAmount}
                                  />
                                  {touched.listingAmount &&
                                    errors.listingAmount && (
                                      <FormFeedback>
                                        {errors.listingAmount}
                                      </FormFeedback>
                                    )}
                                </Col>
                              </Row>

                              <Row>
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Number Of Allowed Gst{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="noOfAllowedGST"
                                    type="number"
                                    placeholder="Enter number of allowed gst"
                                    onChange={e => {
                                      //* Regular expression for decimal numbers
                                      const re = /^[0-9\b]+$/
                                      const isValid = re.test(e.target.value)
                                      if (isValid || e.target.value == "") {
                                        handleChange(e)
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.noOfAllowedGST &&
                                      errors.noOfAllowedGST
                                    }
                                    value={values.noOfAllowedGST}
                                  />
                                  {touched.noOfAllowedGST &&
                                    errors.noOfAllowedGST && (
                                      <FormFeedback>
                                        {errors.noOfAllowedGST}
                                      </FormFeedback>
                                    )}
                                </Col>
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Number Of Billing Cycle{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="noOfBillingCycle"
                                    type="number"
                                    placeholder="Enter Number Of Billing Cycle"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.noOfBillingCycle &&
                                      errors.noOfBillingCycle
                                    }
                                    defaultValue={values.noOfBillingCycle}
                                  />
                                  {touched.noOfBillingCycle &&
                                    errors.noOfBillingCycle && (
                                      <FormFeedback>
                                        {errors.noOfBillingCycle}
                                      </FormFeedback>
                                    )}
                                </Col>
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Billing Cycle{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="billingCycle"
                                    type="select"
                                    placeholder="Enter Billing Cycle"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.billingCycle &&
                                      errors.billingCycle
                                    }
                                    defaultValue={values.billingCycle}
                                  >
                                    <option value={0} key="">
                                      Select Billing Cycle
                                    </option>
                                    {BILLING_CYCLE_DAYS.map((val, i) => (
                                      <option value={val.value} key={i}>
                                        {val.label}
                                      </option>
                                    ))}
                                  </Input>
                                  {touched.billingCycle &&
                                    errors.billingCycle && (
                                      <FormFeedback>
                                        {errors.billingCycle}
                                      </FormFeedback>
                                    )}
                                </Col>
                              </Row>
                              <Row>
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Plan Type{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="planType"
                                    type="select"
                                    placeholder="Enter Plan Type"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.planType && errors.planType
                                    }
                                    value={values.planType}
                                  >
                                    <option value={0} key="">
                                      Select Plan Type
                                    </option>
                                    <option value={"trial"}>Trial</option>
                                    <option value={"subscription"}>
                                      Subscription
                                    </option>
                                  </Input>
                                  {touched.planType && errors.planType && (
                                    <FormFeedback>
                                      {errors.planType}
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">Image</Label>

                                  <a
                                    className="primary mx-3"
                                    href={form.bannerImageUrl}
                                  >
                                    <img
                                      src={form.bannerImageUrl}
                                      width={40}
                                      alt=""
                                    />
                                  </a>
                                  <Input
                                    name="bannerImageUrl"
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    placeholder="Select image"
                                    onChange={async e => {
                                      setSubmitLoading(true)
                                      const data = await fileUpload(e)
                                      setForm({
                                        ...values,
                                        bannerImageUrl: data.url,
                                      })
                                      setSubmitLoading(false)
                                    }}
                                    onBlur={handleBlur}
                                    defaultValue={values?.bannerImageUrl || ""}
                                  />
                                </Col>
                                <Col sm={4} md={4} className="mb-3">
                                  <Col>
                                    <Label className="form-label">
                                      Description{" "}
                                      <span className="text-danger">*</span>{" "}
                                    </Label>
                                    <Input
                                      name="description"
                                      type="textarea"
                                      style={{ height: "30px" }}
                                      rows={6}
                                      placeholder="Enter Description"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.description &&
                                        errors.description
                                          ? true
                                          : false
                                      }
                                      value={values.description}
                                    />
                                    {touched.description &&
                                      errors.description && (
                                        <FormFeedback>
                                          {errors.description}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Col md={4} sm={6} xs={12} className="mb-3">
                                    <Label className="form-label">
                                      Features Plan
                                    </Label>
                                    <span className="text-danger">*</span>
                                    <div className="tags-input-container">
                                      {values.features.map((item, index) => (
                                        <div className="tag-item" key={index}>
                                          <span className="text">{item}</span>
                                          <div
                                            onClick={() => {
                                              values.features.splice(index, 1)
                                            }}
                                          >
                                            <span className="close-tag">
                                              &times;
                                            </span>
                                          </div>
                                        </div>
                                      ))}
                                      <input
                                        type="text"
                                        name="features"
                                        className="tags-input"
                                        placeholder="Enter Tags.."
                                        onKeyDown={e => {
                                          if (e.key !== "Enter") return
                                          const value = e.target.value
                                          if (!value.trim()) return
                                          setForm({
                                            ...values,
                                            features: [
                                              ...values.features,
                                              value,
                                            ],
                                          })
                                          e.target.value = ""
                                        }}
                                      />
                                    </div>
                                    {values.features.length == 0 &&
                                      touched.description && (
                                        <span
                                          className="text-danger"
                                          style={{ fontSize: "10px" }}
                                        >
                                          Please Enter Features tag
                                        </span>
                                      )}
                                  </Col>
                                </Col>
                              </Row>
                            </Row>

                            <Row className="mb-3 text-center">
                              <Col>
                                <Button
                                  size="md"
                                  color="danger"
                                  type="button"
                                  // disabled={submitLoading}
                                  className="mx-2"
                                  onClick={() => {
                                    props.history.push("/plan")
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  bssize="md"
                                  color="info"
                                  onClick={handleSubmit}
                                >
                                  {!isEdit ? "Save" : "Update"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

AddEditPlan.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AddEditPlan
