import { getApiConfig } from "../authHelper"

import * as url from "../url_helper"
import axios from "axios"

//* File upload
export const fileUpload = async e => {
  e.preventDefault()
  const file = e.target.files[0]
  try {
    let formData = new FormData()
    formData.append("file", file)
    const response = await axios.post(
      `${url.API_URL}${url.FILE_UPLOAD}`,
      formData,
      getApiConfig(true)
    )
    return response?.data?.payload
  } catch (error) {}
}
