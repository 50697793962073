import React, { useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import { Form, Formik } from "formik"
import * as Yup from "yup"

import Select from "react-select"

// import UpdateModal from "../../components/Common/UpdateModal"
import Breadcrumb from "components/Common/Breadcrumb"
import SubmitLoader from "components/Common/submitLoader"

import dayjs from "dayjs"

//Import Flatepicker
import Flatpickr from "react-flatpickr"

import ViewDetails from "./viewDetails"
import {
  createCouponCode,
  getCouponCode,
  updateCouponCode,
} from "helpers/backendHelpers/couponCode"
import { getAllUser } from "helpers/backendHelpers/user"

const AddEditCouponCode = props => {
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [user, setUser] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([])

  const [form, setForm] = useState({
    couponCode: "",
    description: "",
    discountType: "",
    discountValue: "",
    expirationDate: "",
    userIds: [],
  })

  const { type, id } = props.match.params || {}
  useEffect(() => {
    document.getElementById("coupon-code").classList.add("mm-active")
    switch (type) {
      case "edit":
        setIsEdit(true)
        setIsView(false)
        break
      case "view":
        setIsView(true)
        setIsEdit(false)
        break
      case "add":
        break
      default:
        setIsView(false)
        setIsEdit(false)
        break
    }
    fetchAllUser()
  }, [isEdit, isView])

  //* fetch All user
  const fetchAllUser = async () => {
    try {
      setSubmitLoading(true)
      let response = await getAllUser()
      response = response.payload.data
      const user = response.map(item => {
        return (item = {
          value: item.userName,
          label: item.userName,
          id: item._id,
        })
      })
      if (id) {
        fetchCouponCodeById(id)
      }
      setUser(user)
      setSubmitLoading(false)
    } catch (error) {}
  }

  //* fetch advocate details
  const fetchCouponCodeById = async id => {
    try {
      let response = await getCouponCode(id)
      response = response.payload?.data[0]
      const userArr = response.userIds.map(item => {
        return (item = {
          label: item.userName,
          value: item.userName,
          id: item._id,
        })
      })
      setSelectedOptions(userArr)
      setForm(response)
    } catch (error) {}
  }

  //* handle add coupon-code
  const handleAddCouponCode = async data => {
    try {
      setSubmitLoading(true)
      await createCouponCode(data)
      props.history.push("/coupon-code")
      setSubmitLoading(false)
    } catch (error) {
      setSubmitLoading(false)
    }
  }

  //* handle Edit coupon-code
  const handleEditCouponCode = async (id, data) => {
    try {
      setSubmitLoading(true)
      await updateCouponCode(id, data)
      props.history.push("/coupon-code")
      setSubmitLoading(false)
    } catch (error) {
      setSubmitLoading(false)
    }
  }

  const handleSelectChange = selectedOptions => {
    setSelectedOptions(selectedOptions)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {submitLoading ? <SubmitLoader /> : <></>}
          {/* <Breadcrumb breadcrumbItem="coupon-code" /> */}
          <Breadcrumb
            title={`${
              type == "add" ? "Add" : type == "view" ? "View" : "Update"
            } Coupon Code`}
          />
          {isView ? (
            <>
              {" "}
              <ViewDetails coupon={form} {...props} />{" "}
            </>
          ) : (
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={form}
                  validationSchema={Yup.object({
                    couponCode: Yup.string().required(
                      "Please Enter coupon code Name"
                    ),
                    description: Yup.string().required(
                      "Please Enter Description"
                    ),

                    discountType: Yup.string().required(
                      "Please Enter Discount Type"
                    ),
                    discountValue: Yup.number().required(
                      "Please Enter Discount"
                    ),
                  })}
                  onSubmit={values => {
                    values.userIds = selectedOptions.map(item => item.id)
                    values.couponCode.toUpperCase()
                    if (isEdit) {
                      handleEditCouponCode(id, values)
                    } else {
                      handleAddCouponCode(values)
                    }
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    setFieldTouched,
                  }) => (
                    <>
                      <Form>
                        <Row>
                          <Col>
                            <Row className="mb-3">
                              <Row>
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Coupon Code{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="couponCode"
                                    type="text"
                                    placeholder="Enter coupon code"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.couponCode && errors.couponCode
                                    }
                                    value={values?.couponCode?.toUpperCase()}
                                  />
                                  {touched.couponCode && errors.couponCode && (
                                    <FormFeedback>
                                      {errors.couponCode}
                                    </FormFeedback>
                                  )}
                                </Col>

                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Discount Type{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="discountType"
                                    type="select"
                                    placeholder="Enter CouponCode Amount"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.discountType &&
                                      errors.discountType
                                    }
                                    value={values.discountType}
                                  >
                                    <option value={0} key="">
                                      Select Discount Type
                                    </option>
                                    <option value="flat" key="">
                                      Flat
                                    </option>
                                    <option value="percentage" key="">
                                      Percentage
                                    </option>
                                  </Input>
                                  {touched.discountType &&
                                    errors.discountType && (
                                      <FormFeedback>
                                        {errors.discountType}
                                      </FormFeedback>
                                    )}
                                </Col>
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Expiration Date{" "}
                                  </Label>
                                  <InputGroup>
                                    <Flatpickr
                                      className="form-control d-block"
                                      placeholder="MM/DD/YYYY"
                                      name="expirationDate"
                                      options={{
                                        altInput: true,
                                        altFormat: "F j, Y",
                                        minDate: new Date(),
                                        dateFormat: "Y-m-d",
                                      }}
                                      defaultValue={values.expirationDate}
                                      onChange={e => {
                                        setForm({
                                          ...values,
                                          expirationDate: dayjs(e[0]).format(),
                                        })
                                      }}
                                    />
                                  </InputGroup>
                                  {/* <span
                                    className="text-danger"
                                    
                                  >
                                    {errors.expirationDate}
                                  </span> */}
                                </Col>
                              </Row>

                              <Row>
                                <Col sm={4} md={4} className="mb-3">
                                  <Col>
                                    <Label className="form-label">
                                      Description{" "}
                                      <span className="text-danger">*</span>{" "}
                                    </Label>
                                    <Input
                                      name="description"
                                      type="textarea"
                                      style={{ height: "80px" }}
                                      rows={6}
                                      placeholder="Enter Description"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.description &&
                                        errors.description
                                          ? true
                                          : false
                                      }
                                      value={values.description}
                                    />
                                    {touched.description &&
                                      errors.description && (
                                        <FormFeedback>
                                          {errors.description}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Col>

                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Discount Value{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="discountValue"
                                    type="number"
                                    placeholder="Enter Discount"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.discountValue &&
                                      errors.discountValue
                                    }
                                    defaultValue={values.discountValue}
                                  />
                                  {touched.discountValue &&
                                    errors.discountValue && (
                                      <FormFeedback>
                                        {errors.discountValue}
                                      </FormFeedback>
                                    )}
                                </Col>

                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">User</Label>
                                  <Select
                                    options={user}
                                    placeholder="Select user"
                                    isMulti
                                    value={selectedOptions}
                                    onChange={handleSelectChange}
                                  />
                                </Col>
                              </Row>
                            </Row>

                            <Row className="mb-3 text-center">
                              <Col>
                                <Button
                                  size="md"
                                  color="danger"
                                  type="button"
                                  // disabled={submitLoading}
                                  className="mx-2"
                                  onClick={() => {
                                    props.history.push("/coupon-code")
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  bssize="md"
                                  color="info"
                                  onClick={handleSubmit}
                                >
                                  {!isEdit ? "Save" : "Update"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

AddEditCouponCode.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AddEditCouponCode
