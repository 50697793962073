import Breadcrumb from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import TableContainer from "components/Common/TableContainer"
import SubmitLoader from "components/Common/submitLoader"
import dayjs from "dayjs"
import { useFormik } from "formik"
import { getAllPlans } from "helpers/backendHelpers/plan"
import Select from "react-select"
import {
  createSubscription,
  deleteSubscription,
  getAllSubscription,
  markAsPaidSubscription,
  updateSubscription,
} from "helpers/backendHelpers/manageSubscription"
import { getAllUser } from "helpers/backendHelpers/user"
import { SimpleStringValue } from "helpers/common_helper_functions"
import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Container,
  Button,
  Badge,
} from "reactstrap"
import * as Yup from "yup"
import SubscriptionModal from "./ViewModal"
import { SaveToast } from "components/Common/SaveToast"

const Subscription = props => {
  const [subscriptions, setSubscriptions] = useState({
    user: [],
    subscriptions: [],
    allPlan: [],
    selectPlanData: null,
  })
  const [loading, setLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [save, setSave] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [isInvoice, setIsInvoice] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [isEdit, setIsEdit] = useState(false)
  const [store, setStore] = useState({
    plan: [],
    user: [],
    planCategory: {},
  })

  const [form, setForm] = useState({
    planId: "",
    userId: "",
    planAmount: 0,
    discountAmount: 0
  })

  const colorStyles = {
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#0080FF" : null,
        color: isFocused ? "white" : "#333333",
      }
    },
  }

  useEffect(() => {
    fetchAllPlanAndUser()
  }, [])

  //* fetch all plan and user
  const fetchAllPlanAndUser = async () => {
    try {
      let user = await getAllUser()
      user = user.payload.data.map(item => {
        return {
          _id: item._id,
          value: item.userName,
          label: item.userName,
        }
      })

      let plan = await getAllPlans()
      //* create nested plan and plan category
      plan = plan.payload.data.map((val, i) => {
        return {
          value: val.name,
          label: val.name,
          options: val.planCategories.map(item => {
            return {
              _id: item._id,
              ...(val.name !== "RISE Plan"
                ? {
                  value: `${item.noOfBillingCycle} ${item.billingCycle}`,
                  label: `${item.noOfBillingCycle} ${item.billingCycle}`,
                  noOfBillingCycle: item.noOfBillingCycle,
                  billingCycle: item.billingCycle,
                  planAmount: item.planAmount,
                  planName: val.name,
                  noOfAllowedUsers: item.noOfAllowedUsers,
                  noOfAllowedGST: item.noOfAllowedGST,
                  expirationDate: dayjs()
                    .add(item.noOfBillingCycle, item.billingCycle)
                    .format("DD-MM-YYYY"),
                }
                : {
                  value: "Custom",
                  label: "Custom",
                  noOfBillingCycle: 0,
                  billingCycle: "",
                  planAmount: 0,
                  planName: val.name,
                  noOfAllowedUsers: 0,
                  noOfAllowedGST: 0,
                  expirationDate: "",
                }),
            }
          }),
        }
      })

      setStore({
        ...store,
        user: user,
        plan: plan,
      })
    } catch (error) { }
  }

  useEffect(() => {
    document.title = "Subscription |  React Admin & Subscription Template"

    fetchAllSubscriptions()
  }, [save])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: form,
    validationSchema: Yup.object({
      planId: Yup.string().required("Please Enter plan name"),
      userId: Yup.string().required("Please Enter User name"),
    }),
    onSubmit: values => {
      values["planAmount"] = subscriptions.selectPlanData.planAmount
      if (values.discountAmount > 0) values["isDiscountApplied"] = true
      if (values.discountAmount > values.planAmount) {
        console.log("error ////// ////// //////");
        SaveToast({ message: "Discount amount should be less than plan amount", type: "error" })
        return
      }
      if (isEdit) {
        return handleUpdateSubscription(form._id, values)
      } else {
        return handleAddSubscription(values)
      }
    },
  })

  //* fetch all Subscriptions
  const fetchAllSubscriptions = async () => {
    try {
      setLoading(true)

      fetchSubscriptions()
      //* fetch All user
      const user = await getAllUser()

      //* fetch All plan
      const plan = await getAllPlans()
      setSubscriptions(prevState => ({
        ...prevState,
        user: user?.payload?.data,
        allPlan: plan?.payload?.data,
      }))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const fetchSubscriptions = async paymentStatus => {
    setLoading(true)
    let subscription = await getAllSubscription(paymentStatus)
    subscription = subscription?.payload?.data
    setSubscriptions(prevState => ({
      ...prevState,
      subscriptions: subscription,
    }))
    setLoading(false)
  }

  //* handle create manage subscription
  const handleAddSubscription = async data => {
    console.log(data)
    try {
      setSubmitLoading(true)
      await createSubscription(data)

      setSubmitLoading(false)
      setSave(!save)
      toggle()
    } catch (error) {
      setSubmitLoading(false)
    }
  }

  //* update manage subscription
  const handleUpdateSubscription = async (id, data) => {
    try {
      setSubmitLoading(true)
      await updateSubscription(id, data)

      setSubmitLoading(false)
      setSave(!save)
      toggle()
    } catch (error) {
      setSubmitLoading(false)
    }
  }

  //* handle add click
  const handleAddButtonClick = () => {
    setIsEdit(false)
    toggle()
  }

  //* open delete modal
  const onClickDelete = Data => {
    setForm(Data)
    setDeleteModal(true)
  }

  //* handle Delete plan
  const handleDeleteSubscription = async () => {
    try {
      await deleteSubscription(form._id)
      setSave(!save)
      setForm({})
      setDeleteModal(false)
    } catch (error) { }
  }

  //* handle update status
  const handleUpdateSubscriptionStatus = async (id, data) => {
    try {
      setLoading(true)
      await updateSubscription(id, data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  // handle update mark as paid
  const updateMarkAsPaid = async id => {
    try {
      setLoading(true)
      await markAsPaidSubscription(id)
      // refresh data
      fetchSubscriptions()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  //* close view modal
  const toggle = () => {
    if (modal) {
      setModal(false)
      setForm({
        userId: "",
        planId: "",
      })
      setSubscriptions(preSave => ({
        ...preSave,
        selectPlanData: null,
      }))
      validation.resetForm()
    } else {
      setModal(true)
    }
  }

  //* toggle view modal
  const toggleViewModal = () => {
    if (modal1 == false) {
      setModal1(true)
    } else {
      setIsInvoice(false)
      setModal1(false)
    }
  }

  const tabToggle = (tab, allValue) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      fetchSubscriptions(allValue)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "User Name",
        accessor: "userDetails",
        filterable: true,
        Cell: cellProps => {
          return cellProps.row.original?.userDetails?.name || "-"
        },
      },
      {
        Header: "Invoice No.",
        accessor: "invoiceNo",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Invoice Date",
        accessor: "createdAt",
        filterable: true,
        Cell: cellProps => {
          return dayjs(cellProps.row.original["createdAt"]).format("DD/MM/YYYY")
        },
      },
      {
        Header: "Plan Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "plan Amount",
        accessor: "planAmount",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Plan Type",
        accessor: "planType",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Payment Mode",
        accessor: "paymentMode",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },

      {
        Header: "Allowed GST",
        accessor: "noOfAllowedGST",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Mark As Paid",
        accessor: "paymentStatus",
        filterable: true,
        Cell: cellProps => {
          const data = cellProps.row.original
          {
            return data.paymentStatus === "paid" ? (
              <Badge color={"success"}>{data.paymentStatus}</Badge>
            ) : (
              <div className="form-check form-switch form-switch-md">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`paymentStatus-${data._id}`}
                  name={`paymentStatus-${data._id}`}
                  defaultChecked={data.paymentStatus === "paid" ? true : false}
                  onChange={e => {
                    let { checked, name } = e.target
                    document.getElementById(name).checked = checked
                    return updateMarkAsPaid(data._id)
                  }}
                />
              </div>
            )
          }
        },
      },
      {
        Header: "Expiration Date",
        accessor: "expiryDate",
        filterable: true,
        Cell: cellProps => {
          const data = cellProps.row.original
          return <span>{new Date(data?.expiryDate).toLocaleDateString()}</span>
        },
      },
      {
        Header: "Active",
        accessor: "isActive",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original
          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input"
                id={`isActive-${cellData._id}`}
                name={`isActive-${cellData._id}`}
                defaultChecked={cellData.isActive}
                onChange={e => {
                  let { checked, name } = e.target
                  document.getElementById(name).checked = checked
                  return handleUpdateSubscriptionStatus(cellData._id, {
                    isActive: checked,
                  })
                }}
              />
            </div>
          )
        },
      },
      {
        Header: "Expired",
        accessor: "isExpired",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original
          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input"
                id={`isExpired-${cellData._id}`}
                name={`isExpired-${cellData._id}`}
                defaultChecked={cellData.isExpired}
                onChange={e => {
                  let { checked, name } = e.target
                  document.getElementById(name).checked = checked
                  return handleUpdateSubscriptionStatus(cellData._id, {
                    isExpired: checked,
                  })
                }}
              />
            </div>
          )
        },
      },

      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <i
                className="bx bxs-file-pdf font-size-24 cursor-pointer"
                id="invoiceTooltip"
                onClick={() => {
                  setForm(cellProps?.row?.original)
                  setIsInvoice(true)
                  toggleViewModal()
                }}
              />
              <UncontrolledTooltip placement="top" target="invoiceTooltip">
                Invoice
              </UncontrolledTooltip>
              <Link
                to="#"
                className="text-primary"
                onClick={() => {
                  setForm(cellProps?.row?.original)
                  toggleViewModal()
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewTooltip" />
                <UncontrolledTooltip placement="top" target="viewTooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  setForm(cellProps.row.original)
                  setSubscriptions(preData => ({
                    ...preData,
                    selectPlanData: cellProps.row.original,
                  }))
                  setIsEdit(true)
                  toggle()
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="editTooltip" />
                <UncontrolledTooltip placement="top" target="editTooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                <UncontrolledTooltip placement="top" target="deleteTooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <SubscriptionModal
        isOpen={modal1}
        isInvoice={isInvoice}
        toggle={toggleViewModal}
        data={form}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSubscription}
        onCloseClick={() => {
          setDeleteModal(false)
          setForm({})
        }}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Manage Subscription" />
          <Row className="subscription-page">
            <Col xs="12">
              <Card>
                <CardBody>
                  <div
                    className="btn-group btn-group-example mb-3"
                    role="group"
                    style={{ position: "absolute", left: "35%", zIndex: "1" }}
                  >
                    <button
                      onClick={() => tabToggle("1", "all")}
                      type="button"
                      className={`btn btn-outline-primary w-sm ${activeTab === "1" ? "active-subscription-status" : ""
                        }`}
                    >
                      All
                    </button>
                    <button
                      onClick={() => tabToggle("2", "paid")}
                      type="button"
                      className={`btn btn-outline-primary w-sm ${activeTab === "2" ? "active-subscription-status" : ""
                        }`}
                    >
                      Paid
                    </button>
                    <button
                      onClick={() => tabToggle("3", "pending")}
                      type="button"
                      className={`btn btn-outline-primary w-sm ${activeTab === "3" ? "active-subscription-status" : ""
                        }`}
                    >
                      Unpaid
                    </button>
                  </div>
                  <TableContainer
                    columns={columns}
                    data={subscriptions?.subscriptions}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    addButtonLabel="Add Manage Subscription"
                    handleAddButtonClick={handleAddButtonClick}
                    dataFetchLoading={loading}
                    customPageSize={100}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/*  */}
          <Modal isOpen={modal} toggle={toggle}>
            {submitLoading ? <SubmitLoader /> : <></>}
            <ModalHeader toggle={toggle} tag="h4">
              {isEdit ? "Edit Manage Subscription" : "Add Manage Subscription"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col xxl={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Plan<span className="text-danger">*</span>
                      </Label>

                      <Select
                        name="options"
                        options={store.plan}
                        styles={colorStyles}
                        value={store.plan.find(
                          val => val.value === validation.values.planName
                        )}
                        onChange={value => {
                          setForm({
                            ...validation.values,
                            planId: value._id,
                            billingCycle: value.billingCycle,
                            noOfBillingCycle: value.noOfBillingCycle,
                            planAmount: value.planAmount,
                            planName: value.planName,
                            noOfAllowedUsers: value.noOfAllowedUsers,
                            noOfAllowedGST: value.noOfAllowedGST,
                            expirationDate: value.expirationDate,
                          })
                          setSubscriptions(prevState => ({
                            ...prevState,
                            selectPlanData: value,
                          }))
                        }}
                      />
                    </div>
                  </Col>

                  {/* <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        Plan<span className="text-danger">*</span>
                      </Label>
                      {isEdit ? (
                        <Input
                          readOnly
                          value={subscriptions?.selectPlanData?.name}
                        />
                      ) : (
                        <Input
                          name="planId"
                          type="select"
                          onChange={val => {
                            const data = JSON.parse(val.target.value)
                            setForm({
                              ...validation.values,
                              planId: data._id,
                            })
                            setSubscriptions(prevState => ({
                              ...prevState,
                              selectPlanData: data,
                            }))
                          }}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.planId &&
                            validation.errors.planId
                              ? true
                              : false
                          }
                        >
                          <option value={0} key="">
                            Select Plan
                          </option>
                          {subscriptions.allPlan.map(val => (
                            <option value={JSON.stringify(val)} key={val._id}>
                              {val.name}
                            </option>
                          ))}
                        </Input>
                      )}
                      {validation.touched.planId && validation.errors.planId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.planId}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col> */}
                </Row>
                {subscriptions?.selectPlanData && (
                  <Row className="mb-3">
                    <Col>
                      <Label>Plan Amount</Label>
                      <Input
                        readOnly
                        // name="planAmount"
                        value={subscriptions?.selectPlanData?.planAmount}
                      />
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        User<span className="text-danger">*</span>
                      </Label>

                      <Select
                        options={store.user}
                        value={
                          store.user[
                          store.user.findIndex(
                            val => val._id === validation.values.userId
                          )
                          ]
                        }
                        clearable={true}
                        onChange={e => {
                          setForm({
                            ...validation.values,
                            userId: e._id,
                          })
                        }}
                      />
                      {validation.touched.planAmount &&
                        validation.errors.planAmount ? (
                        <FormFeedback type="invalid">
                          {validation.errors.planAmount}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label className="form-label">
                        No of Allowed GST<span className="text-danger">*</span>
                      </Label>
                      <Input
                        readOnly
                        value={subscriptions?.selectPlanData?.noOfAllowedGST}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label className="form-label">
                        No of Allowed Users<span className="text-danger">*</span>
                      </Label>
                      <Input
                        readOnly
                        value={subscriptions?.selectPlanData?.noOfAllowedUsers}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label className="form-label">
                        Expiration Date<span className="text-danger">*</span>
                      </Label>
                      <Input
                        readOnly
                        value={subscriptions?.selectPlanData?.expirationDate}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label className="form-label">
                        Discount Amount<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="discountAmount"
                        type="number"
                        onChange={validation.handleChange}
                        value={validation.values.discountAmount}
                      />
                    </div>
                  </Col>

                </Row>
                <Row>
                  <Col className="mb-3">
                    <Label>Payment Mode</Label> <br />
                    <Input
                      name="paymentMode"
                      type="radio"
                      onChange={validation.handleChange}
                      defaultChecked={form.paymentMode == "cash" ? true : false}
                      value={"cash"}
                    />
                    <Label className="mx-3">Cash</Label>
                    <Input
                      name="paymentMode"
                      type="radio"
                      onChange={validation.handleChange}
                      defaultChecked={
                        form.paymentMode == "online" ? true : false
                      }
                      value={"online"}
                    />
                    <Label className="mx-3">Online</Label>
                    <Input
                      name="paymentMode"
                      type="radio"
                      onChange={validation.handleChange}
                      defaultChecked={form.paymentMode == "free" ? true : false}
                      value={"free"}
                    />
                    <Label className="mx-3">Free</Label>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        size="md"
                        color="danger"
                        type="button"
                        // disabled={submitLoading}
                        className="mx-2"
                        onClick={() => {
                          toggle()
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        {isEdit ? "Update" : "Save"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Subscription
