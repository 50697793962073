import { getApiConfig } from "../authHelper"

import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

export const getAllSubscription = paymentStatus => {
  if (paymentStatus !== "all" && paymentStatus) {
    return get(
      `${url.SUBSCRIPTION}?paymentStatus=${paymentStatus}&sortOrder=-1`,
      getApiConfig()
    )
  }
  return get(`${url.SUBSCRIPTION}?sortOrder=-1`, getApiConfig())
}

export const getSubscription = id => {
  return get(`${url.SUBSCRIPTION}?_id=${id}`, getApiConfig())
}

export const getReport = (startDate, endDate) => {
  if (startDate && endDate) {
    return get(
      `${url.USER}/admin/reports?startDate=${startDate}&endDate=${endDate} `,
      getApiConfig()
    )
  }
  return get(`${url.USER}/admin/reports`, getApiConfig())
}

export const createSubscription = data => {
  return post(`${url.SUBSCRIPTION}`, data, getApiConfig())
}

export const updateSubscription = (id, data) => {
  return put(`${url.SUBSCRIPTION}/${id}`, data, getApiConfig())
}

// mark as paid
export const markAsPaidSubscription = id => {
  return put(`${url.SUBSCRIPTION}/mark-as-paid/${id}`, {}, getApiConfig())
}

export const deleteSubscription = id => {
  return del(`${url.SUBSCRIPTION}/${id}`, getApiConfig())
}
