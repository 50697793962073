import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Button, Col, Row, Card, CardBody } from "reactstrap"
import "react-image-lightbox/style.css"

const ViewDetails = props => {
  return (
    <React.Fragment>
      <Row className="mt-3">
        <Col className="text-end">
          <Button
            color="info"
            onClick={() => {
              props.history.push("/coupon-code")
            }}
          >
            Back
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} className="mb-3">
          <Card>
            <Row className="no-gutters align-items-center">
              <Col md={12}>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <table className="table table-striped advocate">
                        <tbody>
                          <tr>
                            <th scope="col">User</th>
                            <td>
                              {props?.coupon.userIds.map(item => (
                                <>
                                  <span>{`${item.userName} , `}</span>
                                </>
                              ))}
                            </td>
                          </tr>
                          <tr>
                            <th scope="col">Coupon Code</th>
                            <td>{props?.coupon.couponCode}</td>
                          </tr>
                          <tr>
                            <th scope="col">Discount Type</th>
                            <td>{props?.coupon.discountType}</td>
                          </tr>

                          <tr>
                            <th scope="col">Discount Value</th>
                            <td>{props?.coupon.discountValue}</td>
                          </tr>

                          <tr>
                            <th scope="col">Expiration Date</th>
                            <td>
                              {new Date(
                                props?.coupon.expirationDate
                              ).toLocaleDateString()}
                            </td>
                          </tr>

                          <tr>
                            <th scope="col">Description</th>
                            <td>{props?.coupon.description}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </CardBody>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

ViewDetails.propTypes = {
  AdvocatesData: PropTypes.object.isRequired,
}

export default ViewDetails
