import { getApiConfig } from "../authHelper"

import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

export const getAllCouponCode = async () => {
  return await get(`${url.COUPON_CODE}?sortOrder=-1`, getApiConfig())
}

export const getCouponCode = id => {
  return get(`${url.COUPON_CODE}?_id=${id}`, getApiConfig())
}

export const createCouponCode = data => {
  return post(`${url.COUPON_CODE}`, data, getApiConfig())
}

export const updateCouponCode = (id, data) => {
  return put(`${url.COUPON_CODE}/${id}`, data, getApiConfig())
}

export const deleteCouponCode = id => {
  return del(`${url.COUPON_CODE}/${id}`, getApiConfig())
}
