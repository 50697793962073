import React from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap"

const ExpenseViewModal = props => {
  const { isOpen, toggle, data } = props
  {
    if (data !== null)
      return (
        <>
          <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={toggle}
          >
            <ModalHeader toggle={toggle}>
              Manage Subscription details
            </ModalHeader>
            <ModalBody>
              <Table>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>{data.name}</td>
                  </tr>
                  <tr>
                    <th>Plan Amount</th>
                    <td>{data.planAmount}</td>
                  </tr>
                  <tr>
                    <th>Expiry Date</th>
                    <td>{new Date(data.expiryDate).toLocaleDateString()}</td>
                  </tr>
                  <tr>
                    <th>Plan Type</th>
                    <td>{data.planType}</td>
                  </tr>

                  <tr>
                    <th>Payment Mode</th>
                    <td>{data.paymentMode}</td>
                  </tr>
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
        </>
      )
  }
}

ExpenseViewModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default ExpenseViewModal
