import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { Formik, useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
import { getUserInfo, setUserInfo } from "helpers/authHelper"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import { getUsersId, updateUsers } from "helpers/backendHelpers/login"
import { SaveToast } from "components/Common/SaveToast"
// import { updateEditUsers } from "helpers/formDataFunction"
import SubmitLoader from "components/Common/submitLoader"
import { FaCamera } from "react-icons/fa"
import dumy_profile from "../../assets/images/dumy-profile.jpeg"
import ImageUploading from "react-images-uploading"
import ProfileMenu from "components/CommonForBoth/TopbarDropdown/ProfileMenu"

const UserProfile = props => {
  //meta title
  document.title = "Profile |  React Admin & Dashboard Template"

  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [profileMessage, setProfileMessage] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [getId, setId] = useState("")
  const [submitLoading, setSubmitLoading] = useState(false)
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    mobileNo: "",
    address: "",
    bio: "",
    profilePic: { fileName: "", file: {} },
  })

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  useEffect(() => {
    const userId = getUserInfo()
    setId(userId._id)
  })

  useEffect(() => {
    let { type } = props.match.params || {}
    switch (type) {
      case "edit":
        setIsEdit(true)
        break
      default:
        setIsEdit(false)
        break
    }
    fetchEditProfile()
  }, [isEdit])

  // const fetchEditProfile = async () => {
  //   try {
  //     setSubmitLoading(true)
  //     const response = await getUsersId(getId)
  //     const result = response.data
  //     setSubmitLoading(false)
  //     setForm(result)
  //   } catch (error) {
  //     setSubmitLoading(false)
  //     const message = error?.response?.message || "Invalid Id"
  //     SaveToast({ message, type: "error" })
  //   }
  // }

  // const handleUpdateProfileSubmit = async (id, data) => {
  //   try {
  //     setSubmitLoading(true)
  //     const formData = new FormData()
  //     formData.append("firstName", data.firstName)
  //     formData.append("lastName", data.lastName)
  //     formData.append("mobileNo", data.mobileNo)
  //     formData.append("address", data.address)
  //     formData.append("bio", data.bio)
  //     formData.append("profilePic", data.profilePic)
  //     const response = await updateEditUsers(id, formData)
  //     let result = response.data.data
  //     setForm(result)
  //     let message = response?.message || "Profile Updated Succesfull"
  //     SaveToast({ message, type: "success" })
  //     props.history.push("/dashboard")
  //     setSubmitLoading(false)
  //   } catch (error) {
  //     setSubmitLoading(false)
  //     const message =
  //       error?.response?.message || "There Was a Problem Updating Profile"
  //     SaveToast({ message, type: "error" })
  //   }
  // }

  // const onChange = async imageList => {
  //   try {
  //     setSubmitLoading(true)
  //     let data = form.profilePic
  //     data = imageList?.[0].file
  //     const formData = new FormData()
  //     formData.append("profilePic", data)
  //     const response = await updateEditUsers(getId, formData)
  //     let result = response.data.data
  //     setUserInfo(result)
  //     setForm(result)
  //     setProfileMessage(true)
  //     setSubmitLoading(false)
  //   } catch (error) {
  //     setSubmitLoading(false)
  //     let message =
  //       error?.response?.message || "There was a problem Image Update"
  //     SaveToast({ message, type: "error" })
  //   }
  // }

  return (
    <React.Fragment>
      <div className="page-content container">
        <Container fluid>
          {submitLoading ? <SubmitLoader /> : <></>}
          {/* Render Breadcrumb */}
          <Breadcrumb title="Skote" breadcrumbItem="Profile" />
          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={form}
                onSubmit={values => {
                  let profileData = values
                  handleUpdateProfileSubmit(getId, profileData)
                }}
              >
                {({
                  touched,
                  errors,
                  values,
                  handleSubmit,
                  handleBlur,
                  handleChange,
                  onImageUpload,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        handleSubmit(e)
                        return false
                      }}
                    >
                      <Row>
                        <Col>
                          <Row className="mb-3">
                            <ImageUploading
                              multiple
                              // value={images}
                              onChange={onChange}
                              // maxNumber={maxNumber}
                              dataURLKey="data_url"
                            >
                              {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                              }) => (
                                <>
                                  {imageList.length === 0 && (
                                    <>
                                      <div className="update-image-container w-100 text-center mb-5">
                                        <div
                                          style={{
                                            width: "100px",
                                            margin: "0 auto",
                                            position: "relative",
                                          }}
                                        >
                                          <img
                                            src={
                                              form.profilePic
                                                ? form.profilePic
                                                : dumy_profile
                                            }
                                            alt={form?.profilePic}
                                            style={{
                                              width: "100%",
                                              aspectRatio: "1",
                                              objectFit: "cover",
                                            }}
                                          />
                                          <label
                                            className="upload-icon"
                                            onClick={onImageUpload}
                                          >
                                            <FaCamera
                                              fill="white"
                                              cursor="pointer"
                                            />
                                          </label>
                                        </div>
                                        {profileMessage ? (
                                          <h5 className="mt-4 text-success">
                                            Profile Updated Succesfull
                                          </h5>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </>
                                  )}
                                  <div className="upload__image-wrapper">
                                    {imageList.map((image, index) => (
                                      <div key={index} className="image-item">
                                        <img src={image["data_url"]} alt="" />
                                        <button
                                          onClick={() => onImageRemove(index)}
                                          className="remove-btn"
                                        >
                                          {/* <GrClose /> */}
                                        </button>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}
                            </ImageUploading>

                            <Col
                              md={4}
                              sm={6}
                              xs={12}
                              className="mt-2 mt-md-0 mb-3"
                            >
                              <Label className="form-label">
                                First Name{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="firstName"
                                type="text"
                                placeholder="Enter your first name"
                                onChange={e => {
                                  handleChange(e)
                                }}
                                onBlur={handleBlur}
                                defaultValue={form.firstName}
                                invalid={touched.firstName && errors.firstName}
                              >
                                {touched.firstName && errors.firstName && (
                                  <FormFeedback>
                                    {errors.firstName}
                                  </FormFeedback>
                                )}
                              </Input>
                            </Col>
                            <Col
                              md={4}
                              sm={6}
                              xs={12}
                              className="mt-2 mt-md-0 mb-3"
                            >
                              <Label className="form-label">
                                Last Name <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="lastName"
                                type="text"
                                placeholder="Enter your last name"
                                onChange={e => {
                                  handleChange(e)
                                }}
                                onBlur={handleBlur}
                                defaultValue={form.lastName}
                                invalid={touched.lastName && errors.lastName}
                              >
                                {touched.lastName && errors.lastName && (
                                  <FormFeedback>{errors.lastName}</FormFeedback>
                                )}
                              </Input>
                            </Col>

                            <Col md={4} sm={6} xs={12} className="mb-3">
                              <Label className="form-label">
                                Mobile Number{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="mobileNo"
                                type="text"
                                placeholder="Enter your mobile number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.mobileNo && errors.mobileNo
                                    ? true
                                    : false
                                }
                                defaultValue={form.mobileNo}
                              >
                                {touched.mobileNo && errors.mobileNo && (
                                  <FormFeedback>{errors.mobileNo}</FormFeedback>
                                )}
                              </Input>
                            </Col>
                          </Row>

                          <Row className="mb-3">
                            <Col md={4} sm={6} xs={12} className="mb-3">
                              <Label className="form-label">Address </Label>
                              <Input
                                name="address"
                                type="text"
                                placeholder="Enter your address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.address && errors.address
                                    ? true
                                    : false
                                }
                                defaultValue={form.address}
                              >
                                {touched.address && errors.address && (
                                  <FormFeedback>{errors.address}</FormFeedback>
                                )}
                              </Input>
                            </Col>
                            <Col md={4} sm={6} xs={12} className="mb-3">
                              <Label className="form-label">Bio </Label>
                              <Input
                                name="bio"
                                type="text"
                                placeholder="Enter your bio"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.bio && errors.bio ? true : false
                                }
                                defaultValue={form.bio}
                              >
                                {touched.bio && errors.bio && (
                                  <FormFeedback>{errors.bio}</FormFeedback>
                                )}
                              </Input>
                            </Col>

                            {/* <Col md={4} sm={6} xs={12} className="mb-3">
                              <Label className="form-label">
                                Profile Images
                              </Label>
                              <Input
                                name="profilePic"
                                type="file"
                                accept=".png, .jpg, .jpeg, .gif"
                                placeholder="Select Profile Pic"
                                onChange={e => {
                                  let tempForm = form
                                  tempForm["profilePic"]["fileName"] =
                                    e.target.files[0].name
                                  tempForm["profilePic"]["file"] =
                                    e.target.files[0]
                                  setForm(tempForm)
                                }}
                                // onBlur={handleBlur}
                                invalid={
                                  touched?.profilePic &&
                                  errors?.profilePic
                                }
                                defaultValue={form?.profilePic?.fileName}
                              />
                              {touched?.profilePic &&
                                errors?.profilePic && (
                                  <FormFeedback>
                                    {errors?.profilePic}
                                  </FormFeedback>
                                )}
                            </Col> */}
                          </Row>

                          <Row className="mb-3 text-center">
                            <Col>
                              <Button
                                size="md"
                                color="danger"
                                type="button"
                                // disabled={submitLoading}
                                className="mx-2"
                                onClick={() => {
                                  props.history.push("/dashboard")
                                }}
                              >
                                Cancel
                              </Button>
                              <Button bssize="md" color="info" type="submit">
                                {!isEdit ? "Save" : "Update"}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
