import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Plan from "pages/Plan"
import AddEditPlan from "pages/Plan/AddEditPlan"
import User from "pages/user"
import CouponCode from "pages/Coupon-code"
import AddEditCouponCode from "pages/Coupon-code/AddEditPlan"
import Announcement from "pages/Announcement"
import Subscription from "pages/manage-subscription"
import ManagePlan from "pages/manage-plan"
import ExpenseCategory from "pages/expense-category/ExpenseCategory"
import Report from "pages/report"
import Notification from "pages/Notification"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile/:type/:id?", component: UserProfile },

  //plan
  { path: "/plan", component: Plan },
  { path: "/plan/:type/:id?", component: AddEditPlan },

  // user
  { path: "/user", component: User },

  // coupon code
  { path: "/coupon-code", component: CouponCode },
  { path: "/coupon-code/:type/:id?", component: AddEditCouponCode },

  //Announcement
  { path: "/announcement", component: Announcement },

  // Notification
  { path: "/notification", component: Notification},

  { path: "/expense-category", component: ExpenseCategory },

  //Manage Subscription
  { path: "/manage-subscription", component: Subscription },

  //Manage enterprise plans
  { path: "/manage-plan", component: ManagePlan },
  { path: "/report", component: Report },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

const sidebar = [
  {
    name: "Dashboard",
    id: "dashboard",
    to: "/",
    icon: "bx bx-home-circle",
  },
  {
    name: "User",
    id: "user",
    to: "/user",
    icon: "fas fa-user-check",
  },
  {
    name: "Plan",
    id: "plan",
    to: "/plan",
    icon: "fa-solid fa-money-check-dollar",
  },
  {
    name: "Coupon Code",
    id: "coupon-code",
    to: "/coupon-code",
    icon: "fas fa-qrcode",
  },
  {
    name: "Announcement",
    id: "announcement",
    to: "/announcement",
    icon: "mdi mdi-bell-ring",
  },
  {
    name: "Notification",
    id: "notification",
    to: "/notification",
    icon: "fas fa-bell",
  },
  {
    name: "Manage Subscription",
    id: "manage-subscription",
    to: "/manage-subscription",
    icon: "fas fa-money-bill-wave",
  },
  {
    name: "Manage Plan & Inquiry",
    id: "manage-plan",
    to: "/manage-plan",
    icon: "bx bx-customize",
  },
  {
    name: "Report",
    id: "report",
    to: "/report",
    icon: "bx bxs-report",
  },
  // {
  //   name: "Expense Category",
  //   id: "expense-category",
  //   to: "/expense-category",
  //   icon: "bx bx-customize",
  // },
]

export { publicRoutes, authProtectedRoutes, sidebar }
