import Breadcrumb from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import TableContainer from "components/Common/TableContainer"
import {
  deleteCouponCode,
  getAllCouponCode,
} from "helpers/backendHelpers/couponCode"
import { SimpleStringValue } from "helpers/common_helper_functions"
import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

const CouponCode = props => {
  const [couponCode, setCouponCode] = useState([])
  const [loading, setLoading] = useState(false)
  const [deleteCoupon, setDeleteCoupon] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [save, setSave] = useState(false)

  useEffect(() => {
    fetchAllCouponCode()
  }, [save])

  //* fetch all coupon code
  const fetchAllCouponCode = async () => {
    try {
      setLoading(true)
      const response = await getAllCouponCode()
      setCouponCode(response?.payload?.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  //* handle add button click
  const handleAddButtonClick = () => {
    props.history.push("/coupon-code/add")
  }

  //* open delete modal
  const onClickDelete = Data => {
    setDeleteCoupon(Data)
    setDeleteModal(true)
  }

  //* handle Delete coupon code
  const handleDeleteCouponCode = async () => {
    try {
      await deleteCouponCode(deleteCoupon._id)
      setSave(!save)
      setDeleteModal(false)
    } catch (error) {}
  }

  const columns = useMemo(
    () => [
      {
        Header: "Coupon Code",
        accessor: "couponCode",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Discount Type",
        accessor: "discountType",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Discount Value",
        accessor: "discountValue",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Expiration Date",
        accessor: "expirationDate",
        filterable: true,
        Cell: cellProps => {
          const data = cellProps.row.original
          return (
            <span>{new Date(data?.expirationDate).toLocaleDateString()}</span>
          )
        },
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },

      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-primary"
                onClick={() => {
                  props.history.push(
                    "/coupon-code/view/" + cellProps.row.original._id
                  )
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  props.history.push(
                    "/coupon-code/edit/" + cellProps.row.original._id
                  )
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  //meta title
  document.title = "Coupon Code | React Admin & CouponCode Template"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCouponCode}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Coupon Code" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={couponCode}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    addButtonLabel="Add Coupon Code"
                    handleAddButtonClick={handleAddButtonClick}
                    dataFetchLoading={loading}
                    customPageSize={100}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CouponCode
